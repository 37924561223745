import React from "react";
import ReactDOM from "react-dom";
import Countdown from "react-countdown";
import { Box, Typography } from "@mui/material";

// Random component
const Completionist = () => <Typography variant='h2' textAlign='center' color='white'>Comença La Casserrenca 2024!</Typography>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed}) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <Box>
        <span>
          <Typography variant='h2' color='#fff' textAlign='center'>{days}dies {hours}h {minutes}m {seconds}s</Typography>
        </span>

      </Box>
    );
  }
};

const CountdownCasserrenca = (props) => {

	return (
    
      <Countdown date={Date.parse('06 Apr 2025 00:00:00 GMT')} renderer={renderer} data_inici={props.data_inici}/>
	);
};

export default CountdownCasserrenca;