import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';

const Input = withStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: 0,
                borderColor: theme.palette.background.secondary,
            },
            '&.Mui-focused fieldset': {
                borderRadius: 0,
            },
            '& .MuiInputBase-input': {
                color: 'black',
            },
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.text.disabled,
        },

        '& .MuiOutlinedInput-input:-webkit-autofill': {
            borderRadius: 0,
        },
    },
}))(TextField);

export default Input;
