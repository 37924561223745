import * as React from 'react';
import ButtonMUI from '@mui/material/Button';

function ButtonAdmin(props) {

  const { variant = 'outlined', action = null } = props;

  return (
      <ButtonMUI variant={variant} startIcon={props.icon} sx={props.style} onClick={action}>
        {props.text}
      </ButtonMUI>
  );
}

export default ButtonAdmin;