import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Page from "../../../components/layouts/Page";
import '../../../css/views/admin/Casserrenques/ItemCasserrenca.css';
import { useSnackbar } from 'notistack';
import image from '../../../assets/img/cartell.png';
import CollapseAnterior from "../../public/Anteriors/CollapseAnterior";
import Edit from "../componentts/Edit";
import ButtonAdmin from "../componentts/ButtonAdmin";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import { deleteElement } from "../../../API/API";

function ItemCasserrenca(props) {

    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar(); 

    function onClickAfegirRecorregut() {
        navigate('casserrenca/'+props.data.id+'/cursa/nou');
    }

    function onClickAfegirFotograf() {
        navigate('casserrenca/'+props.data.id+'/fotograf/nou');
    }

    function onClickAfegirVideo() {
        navigate('casserrenca/'+props.data.id+'/video/nou');
    }

    function onClickEliminarRecorregut(id) {
        eliminarRecorregut(id);
    }
    const eliminarRecorregut = async (id) => {
		const { message } = await deleteElement("eliminarCursa", id);
		enqueueSnackbar(message, {
			variant: "success",
		});
        props.setRefrescar(true);
	};

    function onClickEliminarVideo(id) {
        eliminarVideo(id);
    }
    const eliminarVideo = async (id) => {
		const { message } = await deleteElement("eliminarVideo", id);
		enqueueSnackbar(message, {
			variant: "success",
		});
        props.setRefrescar(true);
	};

    function onClickEliminarFotograf(id) {
        eliminarFotograf(id);
    }
    const eliminarFotograf = async (id) => {
		const { message } = await deleteElement("eliminarFotograf", id);
		enqueueSnackbar(message, {
			variant: "success",
		});
        props.setRefrescar(true);
	};
    //const url = 'http://localhost:8000/storage/';
    const url = "https://api.lacasserrenca.com/api/public/storage/";

	return (
        <Box className='ItemAnterior-portada'>
            <ButtonAdmin text={'Afegir Recorregut'} icon={<AddIcon />} style={{marginTop: '20px'}} action={onClickAfegirRecorregut}/>
            <Typography variant='h5' paddingLeft='15%' marginTop='40px' marginBottom='10px'>RECORREGUTS</Typography>
            <div className="ItemAnterior-separator"></div>
            {props.data?.curses?.map((cursa, index) => {
                return (
                    <div className="ItemAnterior" key={index} style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div className="ItemAnterior-left">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div dangerouslySetInnerHTML={{__html: cursa.nom}} className="ItemAnterior-info"></div>
                                <Edit endPoint='actualitzarCursa' initialValue={cursa?.nom} setInitialValue={props.setInitialValue} name={'nom'} setName={props.setName} setEndPoint={props.setEndPoint} color='#000' setOpenModal={props.setOpenModal} type={'text'} setType={props.setType} label='Nom' setLabel={props.setLabel} id={cursa?.id} setId={props.setId}/>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div className="ItemAnterior-info">{cursa.distancia + 'km'}</div>
                                <Edit endPoint='actualitzarCursa' initialValue={cursa?.distancia} setInitialValue={props.setInitialValue} name={'distancia'} setName={props.setName} setEndPoint={props.setEndPoint} color='#000' setOpenModal={props.setOpenModal} type={'number'} setType={props.setType} label='Distancia' setLabel={props.setLabel} id={cursa?.id} setId={props.setId}/>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div className="ItemAnterior-info">{'+ ' + cursa.desnivell}</div>
                                <Edit endPoint='actualitzarCursa' initialValue={cursa?.desnivell} setInitialValue={props.setInitialValue} name={'desnivell'} setName={props.setName} setEndPoint={props.setEndPoint} color='#000' setOpenModal={props.setOpenModal} type={'number'} setType={props.setType} label='Desnivell' setLabel={props.setLabel} id={cursa?.id} setId={props.setId}/>
                            </div>

                        </div>
                        <div className="ItemAnterior-right">
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} onClick={()=>{onClickEliminarRecorregut(cursa?.id)}}><DeleteIcon /></div>
                            <div style={{display: 'flex', alignItems: 'center', marginLeft: '20px'}}>
                                <a href={cursa.track}>
                                    <div className="ItemAnterior-link" style={{marginLeft: '5%'}}>Track</div>
                                </a>
                                <Edit endPoint='actualitzarCursa' initialValue={cursa?.track} setInitialValue={props.setInitialValue} name={'track'} setName={props.setName} setEndPoint={props.setEndPoint} color='#000' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Enllaç track' setLabel={props.setLabel} id={cursa?.id} setId={props.setId}/>
                            </div>
                        </div>

                    </div>
                )
            })}
            <ButtonAdmin text={'Afegir Fotograf'} icon={<AddIcon />} style={{marginTop: '60px'}} action={onClickAfegirFotograf}/>
            <Typography variant='h5' paddingLeft='15%' marginTop='40px' marginBottom='10px'>FOTOS</Typography>
            <div className="ItemAnterior-separator"></div>
            {props.data?.fotografs?.map((fotograf, index) => {
                return (
                    <div className="ItemAnterior" key={fotograf.id}>
                        <div className="ItemAnterior-top">
                            <div className="ItemAnterior-left" style={{display: 'flex'}}>
                                <div className="ItemAnterior-info">{fotograf.nom}</div>
                                <Edit endPoint='actualitzarFotograf' initialValue={fotograf?.nom} setInitialValue={props.setInitialValue} name={'nom'} setName={props.setName} setEndPoint={props.setEndPoint} color='#000' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Nom fotògraf' setLabel={props.setLabel} id={fotograf?.id} setId={props.setId}/>

                            </div>
                            <div className="ItemAnterior-right">
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
                                <div style={{cursor: 'pointer'}} onClick={()=>{onClickEliminarFotograf(fotograf?.id)}}>
                                    <DeleteIcon />
                                </div>
                                <a href={fotograf.link} style={{marginLeft: '20px'}}>
                                    <div className="ItemAnterior-link">Veure galeria</div>
                                </a>
                                <Edit endPoint='actualitzarFotograf' initialValue={fotograf?.link} setInitialValue={props.setInitialValue} name={'link'} setName={props.setName} setEndPoint={props.setEndPoint} color='#000' setOpenModal={props.setOpenModal} type={'link'} setType={props.setType} label='Enllaç fotògraf' setLabel={props.setLabel} id={fotograf?.id} setId={props.setId}/>

                            </div>
                            </div>
                        </div>
                    </div>
                 )
            })}
            <ButtonAdmin text={'Afegir Video'} icon={<AddIcon />} style={{marginTop: '60px'}} action={onClickAfegirVideo}/>
            <Typography variant='h5' paddingLeft='15%' marginTop='40px' marginBottom='10px'>VIDEOS</Typography>
            <div className="ItemAnterior-separator"></div>
            {props.data?.videos?.map((video, index) => {
                return (
                    <CollapseAnterior data={video} message={'Veure video'} auto={false} video={true} onClickItem={onClickEliminarVideo}/>
                )
            })}
            <Typography variant='h5' paddingLeft='15%' marginTop='40px' marginBottom='10px'>CARTELL</Typography>
            <div className="ItemAnterior-separator"></div>
            <div className="ItemAnterior-cartell">
                <img src={url + props.data?.cartell} className="ItemAnterior-img" />
                <div style={{position: 'absolute', top: 0, right: 0}}>
                    <Edit endPoint='actualitzarCasserrenca' productName={props.data?.nom} setProductName={props.setProductName} initialValue={props.data?.cartell} setInitialValue={props.setInitialValue} name={'cartell'} setName={props.setName} setEndPoint={props.setEndPoint} color='#000' setOpenModal={props.setOpenModal} type={'image'} setType={props.setType} label='Enllaç track' setLabel={props.setLabel} id={props.data?.id} setId={props.setId}/>
                </div>
            </div>
        </Box>
	);
}

export default ItemCasserrenca;
