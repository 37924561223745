import { IconButton, Typography, useMediaQuery } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { List, Box, Button } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import '../../../css/views/public/Botiga/Shopcart.css'
import { useNavigate } from 'react-router';
import ItemShopcart from './ItemShopcart';

const ShopCart = (props) => {
    const navigate = useNavigate();
    const matches = useMediaQuery('(min-width:960px)');

    const listItemsShopCart = () => {
        if (props.shopCart?.length > 0) {
            return props.shopCart?.map((item) => {
                return (
                    <div>
                        {/* <div className="ShopCart-separator"></div> */}
                        <ItemShopcart 
                            item={item.product} 
                            itemShopCartQuantity={item.quantity} 
                            size={item.size}
                            f_removeItemShopCart={props.f_removeItemShopCart}
							f_addItemShopCart={props.f_addItemShopCart}
                        />
                    </div>
                );
            });
        } else {
            return (
                <div className="ShopCart-list-no-items">
                    <Typography variant="h4" textAlign='center'>
                        No hi ha cap producte afegit
                    </Typography>
                    <Typography variant="h4" color='#FF0090' textAlign='center' className="ItemCursa-link" onClick={() => {navigate('/botiga')}}>Anar a la botiga</Typography>
                </div>
            );
        }
    };

    function goToCheckout() {
        if (props.shopCart?.length > 0) {
            navigate('/tramitar');
        }
    }



    return (
        <Box
            sx={matches && { width: 490 }}
            role="presentation"
            className="ShopCart-container"
        >
            <div className="ShopCart-header">
                <div className="ShopCart-header-left">
                    <Typography variant="h3">Cistell</Typography>
                    
                </div>
                <div
                        className=""
                        onClick={props.f_toggleDrawer(false)}
                    >
                        <IconButton className="ShopCart-close">
                            <CloseIcon />
                        </IconButton>
                    </div>
            </div>
            <List className="ShopCart-list">{listItemsShopCart()}</List>
            <div className="ShopCart-footer">
                <div className="ShopCart-separator"></div>
                <div className="ShopCart-price-container">
                    <Typography variant="h5">Total</Typography>
                    <Typography variant="h5">
                        {props.totalPrice?.toFixed(2) + ' €'}
                    </Typography>
                </div>
                <div className='Shopcart-button'>
                    <div className="Shopcart-button-container" onClick={() => {goToCheckout()}}>
                        <Typography variant="body1" className={"Shopcart-button-text " + (props.shopCart?.length === 0 ? 'button-disabled' : '') }>
                            {props.botigaActivada == false ? 'Botiga desactivada' : 'Tramitar'}
                        </Typography>
                    </div>
                </div>
            </div>
        </Box>
    );
};

export default ShopCart;
