import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { get } from "../../API/API";
import Page from "../../components/layouts/Page";
import '../../css/views/public/Reglament.css';
import Loading from "../../layouts/admin/Loading";
import Footer from "../../layouts/public/Footer";

function Reglament(props) {
    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
		const obtenir = async () => {
			const { result } = await get("casserrencaActiva");
			setData(result);
			setLoading(false);
		};
		obtenir();
	}, []);

	return (
		<Page title="La Casserrenca" paddingTop={props.isMobile ? 84 : 104}>
            <Box className='Reglament-container'>
                <Loading loading={loading}>
                    {data?.reglament?.map((regla) => {
                        return (
                            <Box className="Reglament-box">
                                <Typography dangerouslySetInnerHTML={{__html: regla.titol}} variant='h5' paddingLeft='15%' marginTop='40px' marginBottom='10px'></Typography>
                                <div className="ItemAnterior-separator"></div>
                                <Typography dangerouslySetInnerHTML={{__html: regla.contingut}} variant='body1' paddingLeft='' marginTop='40px' marginBottom='10px'></Typography>

                            </Box>
                        )
                    })}
                    
				</Loading>
                
            </Box>
            <Footer />
		</Page>
	);
}

export default Reglament;
