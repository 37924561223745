import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Page from "../../components/layouts/Page";
import '../../css/views/public/Anteriors.css';
import cartell from '../../assets/img/cartell.png';
import { useSnackbar } from 'notistack';
import ItemAnterior from "./Anteriors/ItemAnterior";
import logo_gris from '../../assets/logo_negre.png';
import { get } from "../../API/API";
import Footer from "../../layouts/public/Footer";

function Anteriors(props) {
    
    const [value, setValue] = useState(1);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
	const [anys, setAnys] = useState([
        {
            id: 1,
            nom: '2019'
        },
        {
            id: 2,
            nom: '2020'
        },
        {
            id: 3,
            nom: '2021'
        },
        {
            id: 4,
            nom: '2022'
        }
    ]);
    const [casserrenques, setCasserrenques] = useState([])

    useEffect(() => {
		const obtenir = async () => {
			const { result } = await get("casserrenques");
			setCasserrenques(result);
            setSelected(result[0]);
            setValue(result[0]?.id)
			setLoading(false);
		};
		obtenir();
    }, []);

    const handleChange = (event, newValue) => {
		setValue(newValue);
		const casserrenca = (casserrenques?.filter(item => 
            item.id == newValue
        ));
        setSelected(casserrenca[0]);
	};

	return (
		<Page title="La Casserrenca" paddingTop={props.isMobile ? 84 : 104}>
            <div className="Logo-fons">
				<img src={logo_gris} className='Imatge-fons'/>
			</div>
            <Box className='Anteriors-container'>
                <Tabs
					value={value}
					onChange={handleChange}
                    indicatorColor="#FF0090 !important"
					variant="scrollable"
					scrollButtons
					aria-label="visible arrows tabs example"
				>
					{casserrenques?.map((casserrenca) => (
						<Tab
							label={
								casserrenca.any
							}
							value={casserrenca.id}
						/>
					))}
				</Tabs>
                <ItemAnterior data={selected} />
            </Box>
            <Footer />
		</Page>
	);
}

export default Anteriors;
