import {
    CircularProgress,
    Fade,
    Tooltip,
    Typography,
    IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { deleteElement, deleteLot, get, getAdmin } from '../../../API/API';
import MUIDataTable from 'mui-datatables';
import { options } from '../componentts/optionsTable';
import Title from '../../../layouts/admin/Title';
import { Add, RemoveRedEye } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import Page from '../../../components/layouts/Page';
import { useSnackbar } from 'notistack';
import OrderState from '../../admin/componentts/OrderState';
import moment from 'moment';
import PageAdmin from '../../../components/layouts/PageAdmin';
import '../../../css/views/admin/Comandes/Comandes.css';

const Comandes = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        {
            name: 'state',
            label: 'Estat',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return <OrderState state={value} />;
                },
            },
        },
        {
            name: 'date',
            label: 'Data',
            options: {
                filter: true,
                sort: true,
                sortOrder: 'desc',
                customBodyRender: (value) =>
                    moment(new Date(value)).format('DD/MM/YYYY'),
            },
        },
        {
            name: 'name',
            label: 'Nom',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'phone',
            label: 'Telefon',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'import',
            label: 'Import',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return value + ' €';
                },
            },
        },
        {
            name: 'id',
            label: 'Accions',
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (id) => {
                    return (
                        <>
                            <Tooltip title="Veure informació">
                                <IconButton
                                    onClick={() =>
                                        navigate(
                                            `/admin/comandes/${id}`
                                        )
                                    }
                                >
                                    <RemoveRedEye />
                                </IconButton>
                            </Tooltip>
                        </>
                    );
                },
            },
        },
    ];

    useEffect(() => {
        const getComandes = async () => {
            const { result, message } = await getAdmin('comandes');

            setData(result);
            setLoading(false);
        };
        getComandes();
    }, []);

    const optionsTable = {
        ...options,

        onRowsDelete: (rowsDeleted) => {
            rowsDeleted.data.forEach(async (item) => {
                let message = await deleteElement(
                    'borrarComanda',
                    data[item.dataIndex].id
                );
                enqueueSnackbar(message, {
                    variant: message !== 'error' ? 'success' : 'error',
                });
                console.log(message);
            });
        },
    };

    return (
        <PageAdmin title="Comandes">

            <div className='Comandes-container'>
                        <Title
                            title="Comandes"
                            subtitle="Gestiona les Comandes dels nostres clients"
                            // button={
                            //     <Tooltip title="Crear producte">
                            //         <IconButton onClick={() => navigate('nou')}>
                            //             <Add />
                            //         </IconButton>
                            //     </Tooltip>
                            // }
                        />
                        {!loading ? (
                            <Fade in={!loading}>
                                <div className=''>
                                    <MUIDataTable
                                        data={data}
                                        columns={columns}
                                        options={optionsTable}
                                    />
                                </div>
                            </Fade>
                        ) : (
                            <CircularProgress />
                        )}
            </div>
        </PageAdmin>
    );
};

export default Comandes;
