import { Grid, Tab, Tabs, Typography, Fade, CircularProgress } from "@mui/material";
import { Box, width } from "@mui/system";
import React, { useEffect, useState } from "react";
import Page from "../../components/layouts/Page";
import '../../css/views/public/Botiga.css';
import carousel1 from '../../assets/maillot_culote.png';
import carousel2 from '../../assets/maillot_carousel.png';
import carousel3 from '../../assets/culote.png';
import portada from '../../assets/img/portada.png';
import producte from '../../assets/img/producte.png';
import Producte from "./Botiga/Producte";
import { useSnackbar } from 'notistack';
import { get } from "../../API/API";
import logo_gris from '../../assets/logo_negre.png';
import CarouselCasserrenca from "../../components/elements/CarouselCasserrenca";
import Footer from "../../layouts/public/Footer";
import logoAnimat from '../../assets/logo-animat-negre.gif'

function Botiga(props) {
    
    
	const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const obtenir = async () => {
			const { result } = await get("productes");
			setData(result);
			setLoading(false);
		};
		obtenir();
    }, []);
	
    function renderImages() {
        return (
            <div className="Botiga-Carousel">
                <CarouselCasserrenca images={[carousel1, carousel2, carousel3]} />
            </div>
        )
    }

    function renderProducts() {
        return (
            <Grid container spacing={2} className='Botiga-graella'>
                {data?.length > 0 ?
                    data.map((producte, item) => {
                        return (
                        <Grid item xs={12} md={6} xl={4}>
                            <Producte 
                                data={producte}
                                itemShopCartQuantity={() =>
                                props.searchItemShopCart(producte, 0)
                                }
                                f_addItemShopCart={props.f_addItemShopCart}
                            />
                        </Grid>
                        )
                    }) :
                    <Typography variant='h4' textAlign={'center'} width={'100%'}>Actualment no hi ha cap producte disponible</Typography>
                }
            </Grid>
        )
    }

    const styles={
		width: "40px",
		height: "40px",
		position: "absolute",
		bottom: 0,
		top: 0,
		right: 0,
		left: 0,
		margin: "auto",
        width: "50vw",
        height: "50vh",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
	}

	return (
		<Page title="La Casserrenca" paddingTop={props.isMobile ? 84 : 104}>
            
            {!loading ? (
                <Fade in={!loading}>
                    <div>
                        <div className="Logo-fons">
                            <img src={logo_gris} className='Imatge-fons'/>
                        </div>
                        <div>
                            <Box className='Botiga-container'>
                                {renderImages()}

                                {renderProducts()}
                            </Box>

                            <Footer />
                        </div>
                    </div>
                </Fade>
                ) : (
                    <div style={styles}>
                        <img src={logoAnimat} className=''/>
                    </div>
                )
            }
		</Page>
	);
}

export default Botiga;
