import { Grid, IconButton, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Page from "../../../components/layouts/Page";
import '../../../css/views/admin/Casserrenques/Casserrenques.css';
import { useSnackbar } from 'notistack';
import ItemCasserrenca from "./ItemCasserrenca";
import { get, getElement } from "../../../API/API";
import Loading from "../../../layouts/admin/Loading";
import Title from "../../../layouts/admin/Title";
import { Add, RemoveRedEye } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import PageAdmin from "../../../components/layouts/PageAdmin";
import EditModal from "../componentts/EditModal";

function Casserrenques(props) {

	const [value, setValue] = useState(1);
    const [selected, setSelected] = useState([]);
	const [casserrenques, setCasserrenques] = useState([]);
	const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

	const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const [type, setType] = useState('');
    const [productName, setProductName] = useState('');
    const [name, setName] = useState('');
    const [id, setId] = useState(null);
    const [label, setLabel] = useState('');
    const [endPoint, setEndPoint] = useState('');
    const [initialValue, setInitialValue] = useState(null);
	const [refrescar, setRefrescar] = useState(false);

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await get("casserrenques");
			setCasserrenques(result);
            setSelected(result[0]);
			setValue(result[0]?.id)
			setLoading(false);
		};
		obtenir();
	}, [])

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await get("casserrenques");
			setCasserrenques(result);
			const data = (result?.filter(item => 
                item.id == value
            ));
            setSelected(data[0]);
			setLoading(false);
			setRefrescar(false);
		};
		if (refrescar == true) {
            obtenir();
        }
	}, [refrescar]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		const data = casserrenques.filter(casserrenca => 
			casserrenca.id == newValue
		);
		setSelected(data[0]);
	};

	return (
		<PageAdmin title="Les teves Casserrenques">
			<Box className='Casserrenques-container'>
				<Title
					title="Totes les edicions"
					subtitle="Gestiona les dades de cada edició de la casserrenca, o crea una de nova"
					button={
						<Tooltip title="Crear Casserrenca">
							<IconButton onClick={() => navigate('nou')}>
								<Add />
							</IconButton>
						</Tooltip>
					}
				/>

				<Loading loading={loading}>
					<Box className='Anteriors-container'>
						<Tabs
							value={value}
							onChange={handleChange}
							indicatorColor="#FF0090 !important"
							variant="scrollable"
							scrollButtons
							aria-label="visible arrows tabs example"
						>
							{casserrenques?.map((casserrenca) => {
								return (
									<Tab
										label={
											casserrenca.any
										}
										value={casserrenca.id}
									/>
								)
							})}
						</Tabs>
						<ItemCasserrenca 
							data={selected ? selected : null}
							setInitialValue={setInitialValue}
							setName={setName}
							setEndPoint={setEndPoint}
							setOpenModal={setOpenModal}
							setType={setType}
							setLabel={setLabel}
							setId={setId}
							setProductName={setProductName}
							setRefrescar={setRefrescar}
						/>
						<EditModal 
							open={openModal}
							setOpen={setOpenModal}
							handleOpenModal={handleOpenModal}
							handleCloseModal={handleCloseModal}
							type={type}
							name={name}
							label={label}
							endPoint={endPoint}
							id={id}
							productName={productName}
							initialValue={initialValue}
							setRefrescar={setRefrescar}
						/>
					</Box>
				</Loading>
			</Box>
		</PageAdmin>
	);
}

export default Casserrenques;
