import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import '../../../css/views/public/Anteriors/ItemAnterior.css';
import CollapseAnterior from "./CollapseAnterior";

function ItemAnterior(props) {
	
    
    const url = "https://api.lacasserrenca.com/api/public/storage/";
	
	return (
        <Box className='ItemAnterior-portada'>
            <Typography variant='h5' paddingLeft='15%' marginTop='40px' marginBottom='10px'>RECORREGUTS</Typography>
            <div className="ItemAnterior-separator"></div>
            {props.data?.curses.map((cursa, index) => {
                return (
                    <div className="ItemAnterior" key={index} style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div className="ItemAnterior-left">
                            <div dangerouslySetInnerHTML={{__html: cursa?.nom}} className="ItemAnterior-info"></div>
                            <div className="ItemAnterior-info">{cursa.distancia + 'km'}</div>
                            <div className="ItemAnterior-info">{' + ' + cursa.desnivell+'m'}</div>
                        </div>
                        <div className="ItemAnterior-right">
                            <a href={cursa.track}>
                                <div className="ItemAnterior-link" style={{marginLeft: '5%'}}>Track</div>
                            </a>
                        </div>
                    </div>
                )
            })}
            <Typography variant='h5' paddingLeft='15%' marginTop='40px' marginBottom='10px'>FOTOS</Typography>
            <div className="ItemAnterior-separator"></div>
            {props.data?.fotografs.map((fotograf, index) => {
                return (
                    <div className="ItemAnterior" key={props.data.id}>
                        <div className="ItemAnterior-top">
                            <div className="ItemAnterior-left">
                                <div className="ItemAnterior-info">{fotograf.nom}</div>
                            </div>
                            <div className="ItemAnterior-right">
                                <a href={fotograf.link}>
                                    <div className="ItemAnterior-link">Veure galeria</div>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                )
            })}
            <Typography variant='h5' paddingLeft='15%' marginTop='40px' marginBottom='10px'>VIDEOS</Typography>
            <div className="ItemAnterior-separator"></div>
            {props.data?.videos.map((video, index) => {
                return (
                    <CollapseAnterior data={video} message={'Veure video'} video={true} admin={false}/>
                )
            })}
            <Typography variant='h5' paddingLeft='15%' marginTop='40px' marginBottom='10px'>CARTELL</Typography>
            <div className="ItemAnterior-separator"></div>
            <div className="ItemAnterior-cartell">
                <img src={url+props.data?.cartell} className="ItemAnterior-img" />
            </div>
        </Box>
	);
}

export default ItemAnterior;
