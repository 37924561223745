import React, { useEffect, useState } from "react";
import { SvgIcon, Typography } from "@mui/material";
import '../../css/views/public/components/CarouselCasserrenca.css';
import { useNavigate } from 'react-router';

const CarouselCasserrenca = (props) => {

    const [selected, setSelected] = useState(0);

    const { hideDots = false, auto = true} = props;

    useEffect(() => {
        if (auto) {
            setTimeout(() => {
                if (selected < props.images?.length-1) {
                    setSelected(selected+1);
                } else {
                    setSelected(0);
                }
            }, 5000);
        }
        
    }, [selected])

    function clickDot(index) {
        setSelected(index);
    }

    function renderDots() {
        if (!hideDots) {
            return (
                <div className="CarouselCasserrenca-dots">
                    {props.images.map((image, index) => {
                        return (
                            <div className={"CarouselCasserrenca-dot " + (index == selected ? 'selected-dot' : '')} onClick={() => {clickDot(index)}}></div>
                        )
                    })}
                </div>
            )
        }
        
    }

	return (
		<div className={"CarouselCasserrenca-container"}>
            <div className={"CarouselCasserrenca-content "}>
                <div className="CarouselCasserrenca-carousel">
                    {props.images.map((image, index) => {
                        return (
                            <div key={index} className={"CarouselCasserrenca-image " + (selected == index ? 'selected ' : ' ')} >
                                <img src={image} className={'CarouselCasserrenca-img '} style={{objectFit: (hideDots ? 'cover' : 'contain')}}/>
                            </div>
                        )
                    })}
                </div>
                {renderDots()}
            </div>
		</div>
	)
}

export default CarouselCasserrenca;