import React, { useEffect, useState } from "react";
import Page from "../../components/layouts/Page";
import { useNavigate } from "react-router-dom";
import '../../css/views/public/Intro.css';
import image from '../../assets/img/portada.png';
import logo from '../../assets/logo-animat-blanc.gif';

function Intro() {
	
    const navigate = useNavigate();

    // useEffect(() => {
    //     setTimeout(() => {
    //         navigate('portada');
    //     }, 3000);
    // }, []);
	
	
	return (
        <div>
            <div className="Intro-container fadeOut">
                <img src={image} className='Intro-img'/>
                <div className="Intro-content">
                    <img src={logo} className='Intro-logo'/>
                </div>
            </div>
        </div>
	);
}

export default Intro;
