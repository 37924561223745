import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Page from "../../../components/layouts/Page";
import '../../../css/views/public/Botiga/Producte.css';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';

function Producte(props) {
	const [count, setCount] = useState(1);
    const [stock, setStock] = useState(true);
    const [sizes, setSizes] = useState([]);
    const [sizeSelected, setSizeSelected] = useState(null);
    const [colors, setColors] = useState([]);
    const [colorSelected, setColorSelected] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const { itemShopCartQuantity, item } = props;

    function addItem() {
        const itemShopCart = {
            quantity: count,
            product: props.data,
            size: sizeSelected,
            color: colorSelected
        };
        if (sizeSelected == null) {
            enqueueSnackbar("És necessari seleccionar una talla", {
                variant: 'error',
            });
        } 
        // else if (colorSelected == null) {
        //     enqueueSnackbar("És necessari seleccionar un color", {
        //         variant: 'error',
        //     });
        // }
        else {
            
            props.f_addItemShopCart(itemShopCart);
            enqueueSnackbar("Producte afegit correctament!", {
                variant: 'success',
            });
        }
    }

    function clickSize(size) {
        setSizeSelected(size);
    }

    function clickColor(color) {
        setColorSelected(color);
    }
	
    function renderImage() {
        //const url = 'http://localhost:8000/storage/'
        const url = "https://api.lacasserrenca.com/api/public/storage/";
        return (
            <div className="Producte-image">
                <img src={url+props.data.foto} className='Home-img'/>
            </div>
        )
    }

    function renderSizes() {
        if (props.data.talles != undefined) {
            return (
                <div className="Producte-sizes">
                    {props.data.talles.map((size, index) => {
                        return (
                            <div key={index} className={"Size-container " + (sizeSelected?.id == size.id ? 'size-selected' : '')} onClick={()=>{clickSize(size)}}>
                                <Typography variant="body1" color={(sizeSelected?.id == size.id ? '#fff' : '#333')}>{size.nom}</Typography>
                            </div>
                        )
                    })}
                </div>
            )
        }
        
    }

    function renderDescription() {
        return (
            <Box className='Producte-description'>
                <Typography variant="h6" marginTop='10px'>{props.data.nom}</Typography>
                <Typography variant="body1" marginTop='15px'>{props.data.descripcio}</Typography>
                <div className="Producte-bottom">
                    <Typography variant="body1">{props.data.preu + '€'}</Typography>
                    {renderSizes()}
                </div>
                <div className="Producte-afegir-container">
                    <Typography variant="body1" className="Producte-afegir" onClick={()=>{addItem()}}>Afegeix al cistell</Typography>
                </div>
            </Box>
        )
    }

	return (
		<Box className='Producte-container'>
		    {renderImage()}
            {renderDescription()}
        </Box>
	);
}

export default Producte;
