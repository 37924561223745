import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Page from "../../../components/layouts/Page";
import '../../../css/views/public/Botiga/ItemShopcart.css';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';

function ItemShopcart(props) {
	const [count, setCount] = useState(1);
    const [stock, setStock] = useState(true);
    const [sizes, setSizes] = useState([]);
    const [sizeSelected, setSizeSelected] = useState(null);
    const [colors, setColors] = useState([]);
    const [colorSelected, setColorSelected] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const { itemShopCartQuantity, item, size } = props;


    function add() {
        const data = {
            product: item,
            quantity: 1,
            size: size
        }
        props.f_addItemShopCart(data);
    }

    function remove() {
        const data = {
            product: item,
            quantity: 1,
            size: size
        }
        props.f_removeItemShopCart(data);
    }

    function renderImage() {
        //const url = 'http://localhost:8000/storage/'
        const url = "https://api.lacasserrenca.com/api/public/storage/";
        return(
            <div className="ItemShopcart-image">
                <img src={url + item.foto} className='ItemShopcart-img' />
            </div>
        )
    }

    function renderInfo() {
        return (
            <div className="ItemShopcart-info">
                <Typography variant="h6">{item.nom}</Typography>
                <Typography variant="body1">{'Talla: ' + size.nom}</Typography>
                <div className="ItemShopcart-data">
                    <div className="ItemShopcart-buttons">
                        <div className="ItemShopcart-button" style={{cursor: 'pointer'}} onClick={remove}>-</div>
                        <div className="ItemShopcart-button">
                            <Typography variant="body1">{itemShopCartQuantity}</Typography>
                        </div>
                        <div className="ItemShopcart-button" style={{cursor: 'pointer'}} onClick={add}>+</div>
                    </div>
                    <Typography variant="body1">{item.preu * itemShopCartQuantity + '€'}</Typography>
                </div>
            </div>
        )
    }

	return (
		<Box className='ItemShopcart-container'>
		    {renderImage()}
            {renderInfo()}
        </Box>
	);
}

export default ItemShopcart;
