import { CircularProgress, Fade, Tooltip, IconButton, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';

const Edit = (props) => {

    function renderItem() {
        if (props.extraText != null) {
            return (
                <div style={{display: 'flex'}}>
                    <Typography variant='h4'>{props.extraText}</Typography>
                    <IconButton
                        onClick={() =>{
                            props.setOpenModal(true);
                            props.setId(props.id);
                            props.setLabel(props.label);
                            props.setEndPoint(props.endPoint);
                            props.setName(props.name);
                            if (props.setProductName != undefined) {

                                props.setProductName(props.productName);
                            }
                            if (props.setInitialValue != undefined) {
                                props.setInitialValue(props.initialValue);
                            }
                            props.setType(props?.type);
                        }}
                    >
                        <EditIcon htmlColor={props.color}/>
                    </IconButton>
                </div>
            )
        }
        else {
            return (
                <IconButton
                    onClick={() =>{
                        props.setOpenModal(true);
                        props.setId(props.id);
                        props.setLabel(props.label);
                        props.setEndPoint(props.endPoint);
                        props.setName(props.name);
                        if (props.setProductName != undefined) {

                            props.setProductName(props.productName);
                        }
                        if (props.setInitialValue != undefined) {
                            props.setInitialValue(props.initialValue);
                        }
                        props.setType(props?.type);
                    }}
                >
                    <EditIcon htmlColor={props.color}/>
                </IconButton>
            )
        }
    }

    return (
        renderItem()
    );
};

Edit.defaultProps = {
    type: 'text',
    extraText: null,
    color: '#fff',
    endPoint: ''
}

export default Edit;


