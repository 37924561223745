import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Page from "../../../components/layouts/Page";

function HomeAdmin() {
	return (
		<Page title="Pàgina Administració La Casserrenca">
			<Box>
				<Typography>Pàgina Administració La Casserrenca</Typography>
			</Box>
		</Page>
	);
}

export default HomeAdmin;
