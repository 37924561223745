import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import image from '../../../assets/img/portada.png'
import CarouselCasserrenca from "../../../components/elements/CarouselCasserrenca";
import '../../../css/views/public/Anteriors/ItemAnterior.css';
import YouTube, { YouTubeProps } from 'react-youtube';
import ButtonAdmin from "../../admin/componentts/ButtonAdmin";
import DeleteIcon from '@mui/icons-material/Delete';

function CollapseAnterior(props) {
	
    const { video = false, admin = true} = props;
    const [open, setOpen] = useState(false);

    const onPlayerReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };

    function onClickCollapse() {
        setOpen(!open);
    }
    
	return (
        <div className="ItemAnterior" key={props.data.id}>
            <div className="ItemAnterior-top">
                <div className="ItemAnterior-left">
                    <div className="ItemAnterior-info">{props.data.nom}</div>
                </div>
                <div className="ItemAnterior-right">
                    {admin ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} onClick={()=>{props.onClickItem(props.data?.id)}}><DeleteIcon /></div> : null}
                    <div className="ItemAnterior-link" style={{marginLeft: '20px'}} onClick={onClickCollapse}>{props.message}</div>
                </div>

            </div>
            
            <div className={"ItemAnterior-collapse " + (open ? 'open-collapse' : '')}>
                <div className="ItemAnterior-item" style={{maxWidth: (video ? '100%' : '50%'), display: (video ? 'flex' : 'block'), justifyContent: (video ? 'center' : 'unset')}}>
                    {video ?
                        <YouTube videoId={props.data.video} opts={opts} onReady={onPlayerReady} />  
                        :
                        <CarouselCasserrenca images={[image, image]} auto={props.auto}/>
                    }
                </div>
            </div>
        </div>
	);
}

export default CollapseAnterior;
