import { Fade, Grid, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Page from "../../../components/layouts/Page";
import '../../../css/views/public/Home.css';
import image from '../../../assets/img/portada.png';
import line from '../../../assets/img/line.png';
import anteriors from '../../../assets/img/anteriors.png';
import mask from '../../../assets/img/mask.png';
import maskportada from '../../../assets/img/mask_portada.png';
import arrow from '../../../assets/arrow.svg';
import logo_gris from '../../../assets/logo_negre.png';
import CountdownCasserrenca from "../../public/Home/CountdownCasserrenca";
import Eliminadors from "../../public/Home/Eliminadors";
import ItemCursa from "../../public/Home/ItemCursa";
import Edit from "../componentts/Edit";
import { useSnackbar } from "notistack";
import EditModal from "../componentts/EditModal";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import ButtonAdmin from "../componentts/ButtonAdmin";
import { get } from "../../../API/API";

function HomeEdit(props) {

	const [value, setValue] = useState(1);

    const [loading, setLoading] = useState(true);

    const [casserrenca, setCasserrenca] = useState(null);

	const [selected, setSelected] = useState(null);


    const [eliminadors, setEliminadors] = useState([
        {
            number: 1,
            title: 'Lorem ipsum dolor sit amet',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'
        },
        {
            number: 2,
            title: 'Lorem ipsum dolor sit amet',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'
        },
        {
            number: 3,
            title: 'Lorem ipsum dolor sit amet',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'
        },
    ]);


    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const [type, setType] = useState('');
    const [productName, setProductName] = useState('');
    const [name, setName] = useState('');
    const [id, setId] = useState(null);
    const [label, setLabel] = useState('');
    const [endPoint, setEndPoint] = useState('');
    const [initialValue, setInitialValue] = useState(null);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

	const handleChange = (event, newValue) => {
		setValue(newValue);
        const cursa = (casserrenca?.curses?.filter(item => 
			item.id == newValue
		));
		setSelected(cursa[0]);
	};

    function onClickAfegirCursa() {
        navigate(`/admin/cursa/${selected?.id}/nou`);
    }

    useEffect(() => {
        const obtenir = async () => {
			const { result } = await get("casserrencaActiva");
			setCasserrenca(result);
            const cursa = (result?.curses?.filter(item => 
                item.id == value
            ));
            setSelected(cursa[0]);
			setLoading(false);
		};
		obtenir();
    }, []);

    useEffect(() => {
        const obtenir = async () => {
			const { result } = await get("casserrencaActiva");
			setCasserrenca(result);
            const cursa = (result?.curses?.filter(item => 
                item.id == value
            ));
            setSelected(cursa[0]);
			setLoading(false);
		};
		if (openModal == false) {
            obtenir();
        }
    }, [openModal == false]);

    //const url = 'http://localhost:8000/storage/'
    const url = "https://api.lacasserrenca.com/api/public/storage/";

	
	
	return (
		<Page title="La Casserrenca" timeout={1000} paddingTop={0}>
				<div className="Logo-fons">
					<img src={logo_gris} className='Imatge-fons'/>
				</div>
				<Box className='Home-portada' sx={{height: (window.innerHeight + 'px')}}>
					<div className="Home-portada-mask">
						<img src={maskportada} className='Home-img'/>
					</div>
					<div className="Home-portada-title">
						<div className="Home-portada-top">
                            <Typography variant="h2" color='#fff' textAlign='center'>Pròxima edició</Typography>
                            <Typography variant='h2' color='#fff' textAlign='center' marginBottom='30px' marginTop='10px'>{casserrenca?.data_inici}</Typography>
                            <div className="Home-portada-separator" style={{marginBottom: '15px'}}></div>
							<CountdownCasserrenca data_inici={casserrenca?.data_inici}/>
                            {/* <Edit endPoint='actualitzarCasserrenca' initialValue={casserrenca?.data_inici} setInitialValue={setInitialValue} name={'data_inici'} setName={setName} setEndPoint={setEndPoint} color='#fff' setOpenModal={setOpenModal} type={'text'} setType={setType} label="Data d'inici" setLabel={setLabel} id={casserrenca?.id} setId={setId} productName={'casserrenca'} setProductName={setProductName}/> */}
						</div>
						<div className="Home-portada-bottom">
                            <div style={{display: 'flex'}}>
                                <Typography dangerouslySetInnerHTML={{__html: casserrenca?.text_portada}} variant="h4" color='#fff' marginBottom='50px'></Typography>
                                <Edit endPoint='actualitzarCasserrenca' initialValue={casserrenca?.text_portada} setInitialValue={setInitialValue} name={'text_portada'} setName={setName} setEndPoint={setEndPoint} color='#fff' setOpenModal={setOpenModal} type={'text'} setType={setType} label='Text Portada' setLabel={setLabel} id={casserrenca?.id} setId={setId} productName={'casserrenca'} setProductName={setProductName}/>
                            </div>
							<img src={arrow} className='Home-arrow' onClick={() => {document.getElementById("LaCassarrenca").scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"})}}/>
						</div>
					</div>
					<img src={image} className='Home-img'/>
				</Box>
				<Box className='Home-cursa' sx={{position: 'relative', zIndex: 999}} id='LaCassarrenca'>

                    <div style={{display: 'flex'}}>
                        <Typography dangerouslySetInnerHTML={{__html: casserrenca?.titol}} variant='h2' className="Home-cursa-title" textAlign='center'></Typography>
                        <Edit endPoint='actualitzarCasserrenca' initialValue={casserrenca?.titol} setInitialValue={setInitialValue} name={'titol'} setName={setName} setEndPoint={setEndPoint} color='#000' setOpenModal={setOpenModal} type={'text'} setType={setType} label='Text Portada' setLabel={setLabel} id={casserrenca?.id} setId={setId} productName={'casserrenca'} setProductName={setProductName}/>
					</div>
                    <div style={{display: 'flex', alignItems:'center', marginTop: '40px'}}>
                        <Typography dangerouslySetInnerHTML={{__html: casserrenca?.info_general}} variant='body1' className="Home-cursa-subtitle" marginTop=''></Typography>
                        <Edit endPoint='actualitzarCasserrenca' initialValue={casserrenca?.info_general} setInitialValue={setInitialValue} name={'info_general'} setName={setName} setEndPoint={setEndPoint} color='#000' setOpenModal={setOpenModal} type={'text'} setType={setType} label='Text Portada' setLabel={setLabel} id={casserrenca?.id} setId={setId} productName={'casserrenca'} setProductName={setProductName}/>
					</div>
                    <div style={{position: 'relative', zIndex: '999'}}>
                        <ButtonAdmin text={'Afegir Cursa'} icon={<AddIcon />} style={{marginTop: '20px'}} action={onClickAfegirCursa}/>
                    </div>
					<Tabs
						value={value}
						onChange={handleChange}
						variant="scrollable"
						scrollButtons
						aria-label="visible arrows tabs example"
						sx={{zIndex: 6}}
					>
						{casserrenca?.curses?.map((item) => (
							<Tab
								label={
									item.nom
								}
								value={item.id}
							/>
						))}
					</Tabs>
					<Box className='ItemCursa-portada' marginTop='40px' sx={{position:'relative', zIndex: '999'}}>
                        
                        <div style={{display: 'flex'}}>
                            <Typography variant="h4">{selected?.distancia + 'Km'}</Typography>
                            <Edit endPoint='actualitzarCursa' initialValue={selected?.distancia} setInitialValue={setInitialValue} name={'distancia'} setName={setName} setEndPoint={setEndPoint} color='#000' setOpenModal={setOpenModal} type={'number'} setType={setType} label='Distancia' setLabel={setLabel} id={selected?.id} setId={setId}/>
                            <Typography variant="h4">{' + ' + selected?.desnivell + 'm'}</Typography>
                            <Edit endPoint='actualitzarCursa' initialValue={selected?.desnivell} setInitialValue={setInitialValue} name={'desnivell'} setName={setName} setEndPoint={setEndPoint} color='#000' setOpenModal={setOpenModal} type={'number'} setType={setType} label='Desnivell' setLabel={setLabel} id={selected?.id} setId={setId}/>

                        </div>
                        <div style={{display: 'flex'}}>
                            <Typography dangerouslySetInnerHTML={{__html: selected?.hora_sortida}} variant="body1" marginTop='15px'></Typography>
                            <Edit endPoint='actualitzarCursa' initialValue={selected?.hora_sortida} setInitialValue={setInitialValue} name={'hora_sortida'} setName={setName} setEndPoint={setEndPoint} color='#000' setOpenModal={setOpenModal} type={'text'} setType={setType} label='Data Inici' setLabel={setLabel} id={selected?.id} setId={setId}/>
                        </div>
                        <div style={{display: 'flex'}}>
                            <Typography dangerouslySetInnerHTML={{__html: selected?.hora_arribada}} variant="body1"></Typography>
                            <Edit endPoint='actualitzarCursa' initialValue={selected?.hora_arribada} setInitialValue={setInitialValue} name={'hora_arribada'} setName={setName} setEndPoint={setEndPoint} color='#000' setOpenModal={setOpenModal} type={'text'} setType={setType} label='Data Inici' setLabel={setLabel} id={selected?.id} setId={setId}/>
                        </div>
                        <div style={{position: 'relative'}}>
                            <div style={{position: 'absolute', top: '0', right:'0'}}>
                                <Edit endPoint='actualitzarCursa' initialValue={selected?.foto} setInitialValue={setInitialValue} productName={selected?.nom} setProductName={setProductName} name={'foto'} setName={setName} setEndPoint={setEndPoint} color='#000' setOpenModal={setOpenModal} type={'image'} setType={setType} label='Foto' setLabel={setLabel} id={selected?.id} setId={setId}/>
                            </div>
                            <div className="ItemCursa-image">
                                <img src={url+selected?.foto} className=''/>
                            </div>
                        </div>
                        <div className="ItemCursa-items">
                            <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                                <Typography variant="body1" color='#FF0090' className="ItemCursa-link">Inscripcions</Typography>
                                {/* <Edit endPoint='actualitzarCasserrenca' name={'inscripcions'} setName={setName} setEndPoint={setEndPoint} color='#000' setOpenModal={setOpenModal} type={'link'} setType={setType} label='Enllaç Inscripcions' setLabel={setLabel} id={selected?.id} setId={setId}/> */}
                            </div>
                            <div style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                                <Typography variant="body1" color='#FF0090' className="ItemCursa-link">Recorregut</Typography>
                                <Edit endPoint='actualitzarCursa' name={'track'} setName={setName} setEndPoint={setEndPoint} color='#000' setOpenModal={setOpenModal} type={'link'} setType={setType} label='Enllaç Track' setLabel={setLabel} id={selected?.id} setId={setId}/>
                            </div>
                            <div style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                                <Typography variant="body1" color='#FF0090' className="ItemCursa-link">Reglament</Typography>
                            </div>
                        </div>
                        <div className="ItemCursa-message">
                            <Typography dangerouslySetInnerHTML={{__html: selected?.text}} variant="body1"></Typography>
                            <Edit endPoint='actualitzarCursa' initialValue={selected?.text} setInitialValue={setInitialValue} name={'text'} setName={setName} setEndPoint={setEndPoint} color='#000' setOpenModal={setOpenModal} type={'text'} setType={setType} label='Descripció de la cursa' setLabel={setLabel} id={selected?.id} setId={setId}/>
                        </div>
                    </Box>
				</Box>
				<Box className='Eliminadors-container'>
                    <Edit endPoint='actualitzarCasserrenca' initialValue={casserrenca?.titol_eliminadors} setInitialValue={setInitialValue} name={'titol_eliminadors'} setName={setName} setEndPoint={setEndPoint} color='#fff' setOpenModal={setOpenModal} type={'text'} setType={setType} label='Títol Eliminadors' setLabel={setLabel} id={casserrenca?.id} setId={setId}/>
                    <Typography dangerouslySetInnerHTML={{__html: casserrenca?.titol_eliminadors}} variant="h4" className="Eliminadors-title" color='#fff'></Typography>
                    <Grid container spacing={2}>
                        {casserrenca?.eliminadors?.map((eliminador, index) => {
                            return (
                                <Grid item xs={12} md={4}>
                                    <Box className='Eliminador-container'>
                                        <div className="Eliminador-round">
                                            <div className="Eliminador-number">{index+1}</div>
                                        </div>
                                        <Edit endPoint='actualitzarEliminador' initialValue={selected?.text} setInitialValue={setInitialValue} name={'text'} setName={setName} setEndPoint={setEndPoint} color='#fff' setOpenModal={setOpenModal} type={'text'} setType={setType} label='Text Eliminador' setLabel={setLabel} id={eliminador?.id} setId={setId}/>
                                        <Typography dangerouslySetInnerHTML={{__html: eliminador.text}}  variant="body1" color='#fff' className="Eliminador-text"></Typography>
                                    </Box>
                                </Grid>
                                
                            )
                        })}
                    </Grid>
                </Box>
				<div className="Home-anteriors">
                <div className="Home-anteriors-content">
						<Typography variant="h3" color='#fff' textAlign='center'>Fotos de l'edició 2023</Typography>
						{/* <div className="Home-button" onClick={() => {navigate('https://photos.app.goo.gl/QnESG8EpQ47kGEyE8')}}> */}
						<a href="https://photos.app.goo.gl/QnESG8EpQ47kGEyE8" className="Home-button">

							<Typography variant="body1" className="Home-button-text">Veure fotos</Typography>
						</a>
					</div>
					<div className="Home-mask">
						<img src={mask} className='Home-img'/>
					</div>
					<div className="Home-anteriors-img">
						<img src={anteriors} className='Home-img-anteriors'/>
					</div>
				</div>
                <EditModal 
                    open={openModal}
                    setOpen={setOpenModal}
                    handleOpenModal={handleOpenModal}
                    handleCloseModal={handleCloseModal}
                    type={type}
                    name={name}
                    label={label}
                    endPoint={endPoint}
                    id={id}
                    productName={productName}
                    initialValue={initialValue}
                />
		</Page>
	);
}

export default HomeEdit;
