import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import TopBarPublic from "./TopBarPublic";
import Footer from "./Footer";
import { Drawer } from "@mui/material";
import ShopCart from "../../views/public/Botiga/Shopcart";
import Intro from "../../views/public/Intro";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100%",
		overflow: "hidden",
		width: "100%",
		backgroundColor: "black",
	},
	wrapper: {
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden",
	},
	contentContainer: {
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden",
	},
	content: {
		flex: "1 1 auto",
		height: "100%",
		// overflow: "auto",
	},
}));

const DashboardLayout = (props) => {
    const [sidebar, setSidebar] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		if (window.location.pathname == '/tramitar' || window.location.pathname == '/botiga') {
			setSidebar(false);
		}
	}, [window.location.pathname])

	const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setSidebar(open);
    };

	return (
		<div className={classes.root}>

			<Intro />
			<TopBarPublic isMobile={props.isMobile} toggleDrawer={toggleDrawer} shopCart={props.shopCart}/>
			<div className={classes.wrapper}>
				<div className={classes.contentContainer}>
					<div className={classes.content}>
						<Outlet 
							shopCart={props.shopCart}
							totalPrice={props.totalPrice}
							f_removeItemShopCart={props.f_removeItemShopCart}
							f_addItemShopCart={props.f_addItemShopCart}
						/>
						<Drawer
							anchor={"right"}
							open={sidebar}
							onClose={toggleDrawer(false)}
							PaperProps={{
								sx: {width: (props.isMobile ? '100%' : 'unset')},
							}}
						>
							<ShopCart
								f_toggleDrawer={toggleDrawer}
								shopCart={props.shopCart}
								totalPrice={props.totalPrice}
								f_removeItemShopCart={props.f_removeItemShopCart}
								f_addItemShopCart={props.f_addItemShopCart}
								botigaActivada={props.botigaActivada}
								isMobile={props.isMobile}
							/>
						</Drawer>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashboardLayout;
