import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    TextField,
    Button,
    InputLabel,
    Select,
    Typography,
    MenuItem,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Title from '../../layouts/admin/Title';
import { create, get } from '../../API/API';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { FormControl } from '@mui/material';
import Page from '../../components/layouts/Page';
import Thumb from '../../components/elements/Thumb'
import PageAdmin from '../../components/layouts/PageAdmin';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro'; 

import { DatePicker } from '@mui/x-date-pickers-pro';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        padding: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
    title: {
        padding: 20
    },
    wrap: {
        padding: 40,
        borderRadius: 5,
        boxShadow:
            '-1px 2px 4px -1px rgb(0 0 0 / 3%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 4%)',
    },
}));

const CrearCasserrenca = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [date, setDate] = useState(null);

    const crearProducte = async (values) => {
        
        const result = await create('afegirProducte', values);
        enqueueSnackbar(result.message, {
            variant: 'success',
        });
        navigate(-1, { state: values });
    };

    return (
        <PageAdmin title="Add Product">
            <Formik
                initialValues={{
                    nom: '',
                    any: '',
                    activa: 1,
                    cartell: '',
                }}
                // validationSchema={Yup.object().shape({
                //     name: Yup.string().required('El nom és obligatòri')
                // })}
                onSubmit={(values) => {
                    crearProducte(values);
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div style={{padding: '50px 40px'}}>
                            <Title
                                title={'Crea una nova edició de la Casserrenca'}
                                subtitle={
                                    'Entra les dades per guardar una nova entrada'
                                }
                            />
                        </div>
                        <Box className={classes.wrap}>
                            <Grid container spacing={3}>
                                <Grid item md={6}>
                                    <TextField
                                        error={Boolean(
                                            touched.nom && errors.nom
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.nom && errors.nom
                                        }
                                        label="Nom"
                                        margin="normal"
                                        name="nom"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.nom}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker date={date} onChange={(newValue) => setDate(newValue)}/>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        error={Boolean(
                                            touched.preu &&
                                                errors.preu
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.preu &&
                                            errors.preu
                                        }
                                        label={'Preu'}
                                        margin="normal"
                                        name="preu"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="double"
                                        value={values.preu}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.ordre &&
                                                errors.ordre
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.ordre &&
                                            errors.ordre
                                        }
                                        label={'Ordre'}
                                        margin="normal"
                                        name="ordre"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="number"
                                        value={values.ordre}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.descripcio &&
                                                errors.descripcio
                                        )}
                                        fullWidth
                                        multiline
                                        helperText={
                                            touched.descripcio &&
                                            errors.descripcio
                                        }
                                        label={'Descripció'}
                                        margin="normal"
                                        name="descripcio"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        rows={2}
                                        type="text"
                                        value={values.descripcio}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Box mt={2}>
                                        <Typography>Imatge</Typography>
                                        <input
                                            id="foto"
                                            name="foto"
                                            type="file"
                                            inputProps={{ accept: "image/png, image/jpeg"}}
                                            required={false}
                                            onChange={(event) => {
                                                setFieldValue(
                                                    'foto',
                                                    event.currentTarget.files[0]
                                                );
                                            }}
                                        />
                                    </Box>
                                    <Box p={2}>
                                        <Thumb file={values.foto} />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box my={2} sx={{maxWidth: '50%', paddingTop: '40px', margin: '0 auto'}}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {'crear'}
                                </Button>
                            </Box>
                        </Box>
                    </form>
                )}
            </Formik>
        </PageAdmin>
    );
};

export default CrearCasserrenca;
