import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Button,
    Select,
    Typography,
    TableContainer,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Container,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { changeState, get, getElement, getValue } from '../../../API/API';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { FormControl } from '@mui/material';
import moment from 'moment';
import { useFormik } from 'formik';
import Page from '../../../components/layouts/Page';
import PageAdmin from '../../../components/layouts/PageAdmin';
import Title from '../../../layouts/admin/Title';
import OrderState from '../componentts/OrderState';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
    wrap: {
        padding: 20,
        borderRadius: 5,
        backgroundColor: 'white',
        boxShadow:
            '-1px 2px 4px -1px rgb(0 0 0 / 3%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 4%)',
    },
    img: {
        width: 50,
        height: 50,
        borderRadius: 50,
    },
}));

const ComandaView = () => {
    const [order, setOrder] = useState([]);
    const [lots, setLots] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [colors, setColors] = useState([]);
    const [comandaProductes, setComandaProductes] = useState([]);
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            state: order?.state ? order?.state : 1,
        },
        onSubmit: async (values) => {
            const res = {
                id: order.id,
                state: values.state,
            };
            const {result, message} = await changeState(order.id, res);
            enqueueSnackbar(message, {
                variant: 'success',
            });
        },
    });

    useEffect(() => {
        const getComanda = async () => {
            const { result, message } = await getElement('comanda', id);
            setOrder(result[0]);
            formik.setFieldValue('state', result[0].state);
        };
        getComanda();
    }, []);

    useEffect(() => {
        const getLots = async () => {
            let lots_aux = [];
            let sizes_aux = [];
            let colors_aux = [];
            comandaProductes?.forEach((r) => {
                const fetchLots = new Promise((resolve) => {
                    getElement('producte', r.producte_id).then(
                        (response) => {
                            resolve(response.data);
                        }
                    );
                });
                lots_aux.push(fetchLots);

                const fetchSizes = new Promise((resolve) => {
                    getElement('talla', r.talla_id).then(
                        (response) => {
                            resolve(response.data);
                        }
                    );
                });
                sizes_aux.push(fetchSizes);

                
            });
            let lots = await Promise.all(lots_aux);
            setLots(lots);
            let sizes = await Promise.all(sizes_aux);
            setSizes(sizes);
        };
        //getLots();
    }, [comandaProductes]);

    const usuari = [
        { nom: 'Nom', valor: order?.name },
        { nom: 'E-mail', valor: order?.mail },
        { nom: 'Telèfon', valor: order?.phone },
        { nom: 'Còdi Postal', valor: order?.zipcode },
        { nom: 'Província', valor: order?.province },
        { nom: 'Població', valor: order?.poblation },
        { nom: 'Carrer', valor: order?.street },
        { nom: 'Número', valor: order?.number },
        { nom: 'Pis', valor: order?.flat },
        { nom: 'Preu', valor: order?.import + ' €' },
    ];


    //const urlImage = 'http://localhost:8000/storage/'
    const urlImage = "https://api.lacasserrenca.com/api/public/storage/";

    return (
        <PageAdmin title={'Resum comanda'}>
            <Container maxWidth={false} sx={{paddingTop: '50px'}}>
                <Grid container spacing={3}>
                    <Grid item md={12}>
                        <Box mb={3}>
                            <Title
                                title={'Resum comanda - ID: ' + order?.id}
                                button={
                                    <Box display="flex" alignItems="center">
                                        <Typography style={{ marginRight: 20 }}>
                                            {moment().format('DD/MM/YYYY')}
                                        </Typography>
                                        <OrderState
                                            state={formik.values.state}
                                        />
                                    </Box>
                                }
                            />
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item md={8}>
                                <Box className={classes.column} my={4}>
                                    <Box mb={3}>
                                        <Typography variant="h2">
                                            Dades
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item md={12}>
                                            <TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableBody>
                                                        {usuari.map((row) => (
                                                            <TableRow
                                                                key={row.nom}
                                                            >
                                                                <TableCell
                                                                    align="left"
                                                                    style={{
                                                                        maxWidth: 200,
                                                                        width: 200,
                                                                    }}
                                                                >
                                                                    {row.nom}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="right"
                                                                    style={{
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    {row.valor}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item md={4}>
                                <Box className={classes.column}>
                                    <Typography variant="h2">
                                        Canvi d'estat
                                    </Typography>
                                    <Box m={3}></Box>
                                    <form
                                        onSubmit={formik.handleSubmit}
                                        enableReinitialize={true}
                                    >
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={
                                                    formik.values.state
                                                }
                                                name="state"
                                                onChange={formik.handleChange}
                                            >
                                                <MenuItem value={1}>
                                                    En procés
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    Completada
                                                </MenuItem>
                                                <MenuItem value={3}>
                                                    Cancel·lada
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Box my={3}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                type="submit"
                                            >
                                                Canviar
                                            </Button>
                                        </Box>
                                    </form>
                                </Box>

                                <Box className={classes.column} my={4}>
                                    <Box mb={3}>
                                        <Typography variant="h2">
                                            Lots
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={3}>
                                        <Grid item md={12}>
                                            <TableContainer>
                                                <Table
                                                    sx
                                                    aria-label="simple table"
                                                >
                                                    <TableBody>
                                                        {order.comanda_productes?.map((row, index) => (
                                                            <TableRow
                                                                key={row?.id}
                                                            >
                                                                <TableCell align="left">
                                                                    <img
                                                                        className={
                                                                            classes.img
                                                                        }
                                                                        src={
                                                                            urlImage + row?.producte?.foto
                                                                        }
                                                                    />
                                                                </TableCell>

                                                                <TableCell align="left">
                                                                    {
                                                                        row?.producte?.nom
                                                                    }{' '}
                                                                    (
                                                                    {row?.id}
                                                                    )
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {
                                                                        'Talla:'
                                                                    }{' '}
                                                                    (
                                                                    {row?.talla?.nom}
                                                                    )
                                                                </TableCell>
                                                                <TableCell
                                                                    align="right"
                                                                    style={{
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    {
                                                                        row?.producte?.preu + '€'
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </PageAdmin>
    );
};

export default ComandaView;
