import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Page from "../../../components/layouts/Page";
import '../../../css/views/public/Botiga/Producte.css';
import producte from '../../../assets/img/producte.png';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import Edit from "../componentts/Edit";
import ButtonAdmin from "../componentts/ButtonAdmin";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteElement } from "../../../API/API";

function ProducteAdmin(props) {
	const [count, setCount] = useState(1);
    const [stock, setStock] = useState(true);
    const [sizes, setSizes] = useState([]);
    const [sizeSelected, setSizeSelected] = useState(null);
    const [colors, setColors] = useState([]);
    const [colorSelected, setColorSelected] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    
    function onClickAfegirTalla() {
        navigate('producte/'+props.data.id+'/talla/nou');
    }

    function onClickEliminarProducte() {
        props.f_eliminarProducte(props.data.id);
    }

    function onClickEliminarTalla(id) {
        props.f_eliminarTalla(id);
    }
    
	
    function renderImage() {
        // const url = 'http://localhost:8000/storage/'
        const url = "https://api.lacasserrenca.com/api/public/storage/";
        return (
            <div className="Producte-image">
                <img src={url+props.data.foto} className='Home-img'/>
                <div style={{position: 'absolute', top: '0', right: '0'}}>
                    <Edit productName={props.data.nom} setProductName={props.setProductName} name='foto' setName={props.setName} endPoint='actualitzarProducte' setEndPoint={props.setEndPoint} color='#000' setOpenModal={props.setOpenModal} type={'image'} setType={props.setType} label='Image Background' setLabel={props.setLabel} id={props.data.id} setId={props.setId}/>
                </div>
            </div>
        )
    }

    function renderSizes() {
        if (props.data.talles != undefined) {
            return (
                <div className="Producte-sizes">
                    {props.data.talles.map((size, index) => {
                        return (
                            <div>
                                <div key={index} className={"Size-container " + (sizeSelected?.id == size.id ? 'size-selected' : '')}>
                                    <Typography variant="body1" color={(sizeSelected?.id == size.id ? '#fff' : '#333')}>{size.nom}</Typography>
                                </div>
                                <ButtonAdmin text={''} icon={<DeleteIcon />} variant='' action={() => {onClickEliminarTalla(size.id)}}/>
                            </div>
                        )
                    })}
                </div>
            )
        }
        
    }

    function renderDescription() {
        return (
            <Box className='Producte-description'>
                <div style={{display: 'flex'}}>
                    <Typography variant="h6" marginTop='10px'>{props.data.nom}</Typography>
                    <Edit productName={props.data.nom} setProductName={props.setProductName} name='nom' setName={props.setName} endPoint='actualitzarProducte' setEndPoint={props.setEndPoint} color='#000' setOpenModal={props.setOpenModal} type={'text'} setType={props.setType} label='Nom' setLabel={props.setLabel} id={props.data.id} setId={props.setId}/>
                </div>
                <div style={{display: 'flex'}}>
                    <Typography variant="body1" marginTop='15px'>{props.data.descripcio}</Typography>
                    <Edit productName={props.data.nom} setProductName={props.setProductName} name='descripcio' setName={props.setName} endPoint='actualitzarProducte' setEndPoint={props.setEndPoint} color='#000' setOpenModal={props.setOpenModal} type={'text'} setType={props.setType} label='Descripcio' setLabel={props.setLabel} id={props.data.id} setId={props.setId}/>
                </div>
                <div className="Producte-bottom">
                    <div style={{display: 'flex'}}>
                        <Typography variant="body1">{props.data.preu + '€'}</Typography>
                        <Edit productName={props.data.nom} setProductName={props.setProductName} name='preu' setName={props.setName} endPoint='actualitzarProducte' setEndPoint={props.setEndPoint} color='#000' setOpenModal={props.setOpenModal} type={'text'} setType={props.setType} label='Preu' setLabel={props.setLabel} id={props.data.id} setId={props.setId}/>
                    </div>
                    <div>
                        <ButtonAdmin text={'Afegir Talla'} icon={<AddIcon />} style={{marginTop: '20px'}} action={onClickAfegirTalla}/>
                        {renderSizes()}
                    </div>
                </div>
            </Box>
        )
    }

	return (
		<Box className='Producte-container' sx={{position: 'relative'}}>
            <div style={{position: 'absolute', top: '0', left: '0', zIndex: '10'}}>
                <ButtonAdmin text={'Eliminar producte'} icon={<DeleteIcon />} variant='contained' action={onClickEliminarProducte}/>
            </div>
		    {renderImage()}
            {renderDescription()}
        </Box>
	);
}

export default ProducteAdmin;
