import { CircularProgress, Fade, Grid, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Page from "../../../components/layouts/Page";
import '../../../css/views/public/Botiga.css';
import botiga from '../../../assets/img/botiga.png';
import producte from '../../../assets/img/producte.png';
import Producte from "./ProducteAdmin";
import { useSnackbar } from 'notistack';
import { deleteElement, get } from "../../../API/API";
import ButtonAdmin from "../componentts/ButtonAdmin";
import AddIcon from '@mui/icons-material/Add';
import Edit from "../componentts/Edit";
import { useNavigate } from "react-router-dom";
import EditModal from "../componentts/EditModal";
import PageAdmin from "../../../components/layouts/PageAdmin";
import carousel1 from '../../../assets/maillot_culote.png';
import carousel2 from '../../../assets/maillot_carousel.png';
import carousel3 from '../../../assets/culote.png';
import CarouselCasserrenca from "../../../components/elements/CarouselCasserrenca";

function BotigaAdmin(props) {
    
    const navigate = useNavigate();
	const [data, setData] = useState([
        {
            id: 1,
            name: 'Lorem ipsum',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            price: 20,
            sizes: [
                {
                    id: 1,
                    name:'XS'
                },
                {
                    id: 2,
                    name:'S'
                },
                {
                    id: 3,
                    name:'M'
                },
                {
                    id: 4,
                    name:'L'
                },
                {
                    id: 5,
                    name:'XL'
                }
            ],
            image: producte
        },
        {
            id: 2,
            name: 'Lorem ipsum 2',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            price: 20,
            sizes: [
                {
                    id: 1,
                    name:'XS'
                },
                {
                    id: 2,
                    name:'S'
                },
                {
                    id: 3,
                    name:'M'
                },
                {
                    id: 4,
                    name:'L'
                },
                {
                    id: 5,
                    name:'XL'
                }
            ],
            image: producte
        },
        {
            id: 3,
            name: 'Lorem ipsum 3',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            price: 20,
            sizes: [
                {
                    id: 1,
                    name:'XS'
                },
                {
                    id: 2,
                    name:'S'
                },
                {
                    id: 3,
                    name:'M'
                },
                {
                    id: 4,
                    name:'L'
                },
                {
                    id: 5,
                    name:'XL'
                }
            ],
            image: producte
        },
    ]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const [type, setType] = useState('');
    const [productName, setProductName] = useState('');
    const [name, setName] = useState('');
    const [id, setId] = useState(null);
    const [label, setLabel] = useState('');
    const [endPoint, setEndPoint] = useState('');

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const obtenir = async () => {
			const { result } = await get("productes");
			setData(result);
			setLoading(false);
		};
		obtenir();
    }, []);

    useEffect(() => {
        const obtenir = async () => {
			const { result } = await get("productes");
			setData(result);
			setLoading(false);
		};
		obtenir();
        setLoading(false);
    }, [loading==true]);

    useEffect(() => {
        const obtenir = async () => {
			const { result } = await get("productes");
			setData(result);
			setLoading(false);
		};
		obtenir();
        setLoading(false);
    }, [openModal]);

    function onClickAfegirProducte() {
        navigate('producte/nou');
    }

    const eliminarProducte = async (id) => {
		const { message } = await deleteElement("eliminarProducte", id);
		enqueueSnackbar(message, {
			variant: "success",
		});
        setLoading(true);
	};

    const eliminarTalla = async (id) => {
		const { message } = await deleteElement("eliminarTalla", id);
		enqueueSnackbar(message, {
			variant: "success",
		});
        setLoading(true);
	};

    function renderImages() {
        return (
            <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', top: '0', right: '0', zIndex:'10'}}>
                    <Edit endPoint='update-image' setEndPoint={setEndPoint} color='#000' setOpenModal={setOpenModal} type={'image'} setType={setType} label='Image Background' setLabel={setLabel} id='image_primary' setId={setId}/>
                </div>
                <div className="Botiga-Carousel">
                    <CarouselCasserrenca images={[carousel1, carousel2, carousel3]} />
                </div>
            </div>
        )
    }

    function renderProducts() {
        return (
            <Grid container spacing={2} className='Botiga-graella'>
                {data?.length > 0 ?
                    data.map((producte, item) => {
                        return (
                        <Grid item xs={12} md={6} xl={4}>
                            <Producte 
                                data={producte}
                                itemShopCartQuantity={() =>
                                props.searchItemShopCart(producte, 0)
                                }
                                f_addItemShopCart={props.f_addItemShopCart}
                                f_eliminarProducte={eliminarProducte}
                                f_eliminarTalla={eliminarTalla}
                                openModal={openModal}
                                setOpenModal={setOpenModal}
                                handleOpenModal={handleOpenModal}
                                handleCloseModal={handleCloseModal}
                                setType={setType}
                                id={id}
                                setId={setId}
                                label={label}
                                setLabel={setLabel}
                                setEndPoint={setEndPoint}
                                name={name}
                                setName={setName}
                                setProductName={setProductName}
                            />
                        </Grid>
                        )}) :
                    <Typography variant='h4' textAlign={'center'} width={'100%'}>Actualment no hi ha cap producte disponible</Typography>
                }
            </Grid>
        )
    }

	return (
		<PageAdmin title="La Casserrenca">
            {!loading ? (
                <Fade in={!loading}>
                    <Box className='Botiga-container' style={{margin: '5%', marginTop: 0}}>
                        {renderImages()}
                        <ButtonAdmin text={'Afegir Producte'} icon={<AddIcon />} style={{marginTop: '20px'}} action={onClickAfegirProducte}/>
                        {renderProducts()}
                    </Box>
                </Fade>
                ) : (
                    <CircularProgress />
                )
            }
            <EditModal 
                open={openModal}
                setOpen={setOpenModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                type={type}
                name={name}
                label={label}
                endPoint={endPoint}
                id={id}
                productName={productName}
            />
		</PageAdmin>
	);
}

export default BotigaAdmin;
