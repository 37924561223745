import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../../components/layouts/Page";
import '../../../css/views/public/Home/ItemCursa.css';

function ItemCursa(props) {
	
    const navigate = useNavigate();
    // const url = 'http://localhost:8000/storage/'
    const url = "https://api.lacasserrenca.com/api/public/storage/";

	return (
        <Box className='ItemCursa-portada' marginTop='40px'>
            <Typography variant="h4">{props.data?.distancia + 'Km + ' + props.data?.desnivell + 'm'}</Typography>
            <Typography dangerouslySetInnerHTML={{__html: props.data?.hora_sortida}} variant="body1" marginTop='15px'></Typography>
            <Typography dangerouslySetInnerHTML={{__html: props.data?.hora_arribada}} variant="body1"></Typography>
            <div className="ItemCursa-image">
                <img src={url+props.data?.foto} className='' style={{zIndex: '99'}}/>
            </div>
            <div className="ItemCursa-items">
                <Typography variant="body1" color='#FF0090' className="ItemCursa-link"><a href="https://www.inscripcions.cat/lacasserrenca2023/">Inscripcions</a></Typography>
                <Typography variant="body1" color='#FF0090' className="ItemCursa-link"><a href={props.data?.track}>Recorregut</a></Typography>
                <Typography variant="body1" color='#FF0090' className="ItemCursa-link" onClick={() => {navigate('/reglament')}}>Reglament</Typography>
            </div>
            <div className="ItemCursa-message">
                <Typography dangerouslySetInnerHTML={{__html: props.data?.text}} variant="body1"></Typography>
            </div>
        </Box>
	);
}

export default ItemCursa;
