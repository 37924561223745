import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Login, sendEmail } from "../../../API/API";
import Page from "../../../components/layouts/Page";
import CustomButton from "../../../components/elements/CustomButton";
import theme from "../../../theme";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "auto",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	pill: {
		background: theme.color.background,
		padding: 50,
		borderRadius: 20,
		boxShadow: "20px 20px 48px #eee,-20px -20px 48px #eee",
	},
}));

const Contacte = () => {
	const classes = useStyles();
	const [error, setError] = useState("");
	let navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

	const schema = Yup.object().shape({
		email: Yup.string()
			.email("Ha de ser un email vàlid")
			.max(255)
			.required("L'email és obligatori"),
		message: Yup.string()
			.max(255)
			.required("El missatge és obligatori"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schema),
	});

	const enviarContacte = async (values) => {
        const result = await sendEmail('send-contact', values);
        enqueueSnackbar(result, {
            variant: result !== 'error' ? 'success' : 'error',
        });
	};

	return (
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				className={classes.root}
			>
                <form onSubmit={handleSubmit(enviarContacte)}>
                    <Typography color="#fff" variant="body1">
                        {"El teu mail:"}
                    </Typography>
                    <TextField
                        fullWidth
                        label=""
                        margin="normal"
                        name="email"
                        type="email"
                        variant="outlined"
                        classes='Contacte-field'
                        sx={{background: '#181818', borderRadius: '15px', border: 'solid white', color: 'white'}}
                        InputProps={{
                            sx: {color: 'white'},
                        }}
                        {...register("email")}
                    />
                    <Typography color="#fff" variant="body1">
                        {"Com et podem ajudar?"}
                    </Typography>
                    <TextField
                        fullWidth
                        label={""}
                        margin="normal"
                        name="message"
                        type="message"
                        variant="outlined"
                        multiline
                        minRows={8}
                        sx={{background: '#181818', borderRadius: '15px', border: 'solid white', color: 'white'}}
                        InputProps={{
                            sx: {color: 'white'},
                        }}
                        {...register("message")}
                    />
                    <Typography
                        variant="body1"
                        style={{ color: theme.color.primary }}
                    >
                        {error}
                    </Typography>

                    <Box my={2} style={{background: '#eee', margin: '0 auto', display: 'flex', justifyContent: 'center', width: 100, borderRadius: '25px'}}>
                        <CustomButton
                            type="submit"
                            title="Enviar"
                            
                        />
                    </Box>
                </form>
			</Box>
	);
};

export default Contacte;
