import React, { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import theme from "./theme";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import { Grow, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { getUser } from "./API/API";
import Loading from "./components/layouts/Loading";
import { useSnackbar } from 'notistack';

function App() {
	const notistackRef = React.createRef();
	const onClickDismiss = (key) => () => {
		notistackRef.current.closeSnackbar(key);
	};
	const [logged, setLogged] = useState(true);
	const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(getIsMobile())
	// const { enqueueSnackbar } = useSnackbar();

	function getIsMobile() {
        return window.innerWidth < 1024;
    }


	

	const [shopCart, setShopCart] = useState(
        JSON.parse(localStorage.getItem('shopCart'))
            ? JSON.parse(localStorage.getItem('shopCart'))
            : []
    );
    const [totalPrice, setTotalPrice] = useState(
        JSON.parse(localStorage.getItem('totalPrice'))
            ? JSON.parse(localStorage.getItem('totalPrice'))
            : 0
    );

    // useEffect(() => {
    //     setShopCart(JSON.parse(localStorage.getItem('shopCart')));
    //     setTotalPrice(JSON.parse(localStorage.getItem('totalPrice')));
    // }, [localStorage.getItem('shopCart')]);

	const searchItemShopCart = (item, quantity) => {
        shopCart.map((shopCartItem) => {
            if (shopCartItem.product.id == item.id) {
                quantity = shopCartItem.quantity;
            }
        });
        return quantity;
    };

    function isInShopCart(product, size, color) {
        if (shopCart && shopCart.length > 0) {
            return shopCart.filter((shopCartItem) => {
                return shopCartItem.product.id === product.id && shopCartItem.size.id === size.id;
            });
        } else return false;
    }

    const addItemShopCart = (item) => {
        // actualitzem el preu
        const price =
            parseFloat(item.product.preu * item.quantity) + totalPrice;
        setTotalPrice(price);

        // comprovem si el producte és al shopcart
        let itemInShopCart = isInShopCart(item.product, item.size, item.color);
        if (itemInShopCart && itemInShopCart.length > 0) {
            // si hi es, sumem 1 a la quantitat
            itemInShopCart = itemInShopCart[0];
            itemInShopCart.quantity += item.quantity;
            setShopCart(shopCart);
            localStorage.setItem('shopCart', JSON.stringify(shopCart));
        } else {
            // si no, l'afegim al shopcart
            const newShopCart = [...shopCart, item];
            setShopCart(newShopCart);
            localStorage.setItem('shopCart', JSON.stringify(newShopCart));
        }

        // enqueueSnackbar("S'ha afegit a la cistella", {
        //     variant: 'success',
        // });
        //actualitzem localStorage
        localStorage.setItem('totalPrice', price);
    };

    const removeItemShopCart = (item) => {
        // actualitzem el preu
        const price = totalPrice - parseFloat(item.product.preu);
        setTotalPrice(price);

        if (shopCart && shopCart.length > 0) {
            shopCart.find((shopCartItem) => {
                if (
                    shopCartItem &&
                    shopCartItem.product.id === item.product.id &&
                    shopCartItem.size.id === item.size.id
                    // shopCartItem.color.color_id === item.color.color_id 
                ) {
                    shopCartItem.quantity -= 1;
                    if (shopCartItem.quantity < 1) {
                        var i = shopCart.indexOf(shopCartItem);
                        if (i != -1) {
                            shopCart.splice(i, 1);
                        }
                    }
                    localStorage.setItem('shopCart', JSON.stringify(shopCart));
                    localStorage.setItem('totalPrice', price);
                }
            });
        }
    };

	const routing = useRoutes(routes(logged, addItemShopCart, removeItemShopCart, totalPrice, shopCart, isMobile, setShopCart, setTotalPrice));

	useEffect(() => {
		const get = async () => {
			const userExist = JSON.parse(localStorage.getItem("user"));
			if (userExist) {
				const { message, data } = await getUser();
				if (message === 200) {
					//userExist.role_id = data.role_id;
					localStorage.setItem("isLoggedIn", true);
					localStorage.setItem("user", JSON.stringify(userExist));
					setLogged(true);
					setLoading(false);
				} else {
					setLogged(false);
					setLoading(false);
				}
			} else {
				setLogged(false);
				setLoading(false);
			}
		};
		get();
	}, [routing]);



	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider
				maxSnack={3}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				TransitionComponent={Grow}
				ref={notistackRef}
				action={(key) => (
					<IconButton onClick={onClickDismiss(key)}>
						<Close style={{ color: "white" }} />
					</IconButton>
				)}
			>
				<Loading loading={loading}>{routing}</Loading>
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;
