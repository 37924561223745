import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import { Typography, Button } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import { useLocation, useNavigate } from 'react-router';
import { makeStyles } from '@mui/styles';
import Page from '../../../components/layouts/Page';
import Footer from '../../../layouts/public/Footer';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '70vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    icon: {
        color: theme.palette.text.main,
    },
}));

const Finalitzar = () => {
    let location = useLocation();
    const classes = useStyles();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (location.state === 'error') {
        return (
            <Page title="Finalitzar compra">
                <Box className={classes.root}>
                    <ErrorIcon className={classes.icon} sx={{ fontSize: 80 }} />
                    <Box my={2}>
                        <Typography
                            variant="h1"
                            color="primary"
                            textAlign="center"
                        >
                            Hi ha hagut un error en la teva comanda
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <Typography variant="h4" textAlign="center">
                            Siusplau, torna-ho a intentar o posa't en contacte
                            amb nosaltres.
                        </Typography>
                    </Box>
                    <Button
                        color="primary"
                        size="large"
                        type="submit"
                        onClick={() => navigate('/botiga')}
                    >
                        Tornar a la botiga
                    </Button>
                </Box>
            </Page>
        );
    } else {
        return (
            <Page title="Finalitzar compra">
                <Box className={classes.root}>
                    <CheckCircle
                        className={classes.icon}
                        sx={{ fontSize: 80 }}
                    />
                    <Box my={2}>
                        <Typography
                            variant="h1"
                            color="primary"
                            textAlign="center"
                        >
                            Comanda completada
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <Typography variant="h2" textAlign="center" color={'#ff0090'}>
                            Gràcies, {location.state.nom}
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <Typography
                            variant="h4"
                            textAlign="center"
                            style={{ padding: '0 20px' }}
                        >
                            En breus rebràs un correu electrònic a{' '}
                            {location.state.email} per fer el pagament via
                            transferència bancaria
                        </Typography>
                    </Box>
                    <Button
                        color="primary"
                        size="large"
                        type="submit"
                        onClick={() => navigate('/botiga')}
                    >
                        Tornar a la botiga
                    </Button>
                </Box>
            </Page>
        );
    }
};

export default Finalitzar;
