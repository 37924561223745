import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { deleteElement, get } from "../../../API/API";
import Page from "../../../components/layouts/Page";
import Loading from "../../../layouts/admin/Loading";
import '../../../css/views/public/Reglament.css';
import Edit from "../componentts/Edit";
import EditModal from "../componentts/EditModal";
import PageAdmin from "../../../components/layouts/PageAdmin";
import ButtonAdmin from "../componentts/ButtonAdmin";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

function ReglamentAdmin(props) {
    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const [type, setType] = useState('');
    const [productName, setProductName] = useState('');
    const [name, setName] = useState('');
    const [id, setId] = useState(null);
    const [label, setLabel] = useState('');
    const [endPoint, setEndPoint] = useState('');

    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
		const obtenir = async () => {
			const { result } = await get("casserrencaActiva");
			setData(result);
			setLoading(false);
		};
		obtenir();
	}, []);

    useEffect(() => {
		const obtenir = async () => {
			const { result } = await get("casserrencaActiva");
			setData(result);
			setLoading(false);
		};
        if (openModal == false) {
            obtenir();
        }
	}, [openModal]);

    useEffect(() => {
		const obtenir = async () => {
			const { result } = await get("casserrencaActiva");
			setData(result);
			setLoading(false);
		};
        if (loading == true) {
            obtenir();
        }
	}, [loading]);

    function onClickAfegirReglament() {
        navigate('casserrenca/'+data?.id+'/nou');
    }

    function onClickEliminarReglament(id) {
        eliminarReglament(id);
    }
    const eliminarReglament = async (id) => {
		const { message } = await deleteElement("eliminarReglament", id);
		enqueueSnackbar(message, {
			variant: "success",
		});
        setLoading(true);
	};

	return (
		<PageAdmin title="La Casserrenca">
            <Box className='Reglament-container'>
                <Loading loading={loading}>
                    <ButtonAdmin text={'Afegir Reglament'} icon={<AddIcon />} style={{marginTop: '20px'}} action={onClickAfegirReglament}/>
                    {data?.reglament?.map((regla) => {
                        return (
                            <Box className="Reglament-box">

                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} onClick={()=>{onClickEliminarReglament(regla.id)}}><DeleteIcon /></div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Typography dangerouslySetInnerHTML={{__html: regla.titol}} variant='h5' paddingLeft='15%' marginTop='40px' marginBottom='10px'></Typography>
                                    <Edit productName={productName} setProductName={setProductName} name='titol' setName={setName} endPoint='actualitzarReglament' setEndPoint={setEndPoint} color='#000' setOpenModal={setOpenModal} type={'text'} setType={setType} label='titol' setLabel={setLabel} id={regla.id} setId={setId}/>
                                </div>
                                <div className="ItemAnterior-separator"></div>
                                <Edit productName={productName} setProductName={setProductName} name='contingut' setName={setName} endPoint='actualitzarReglament' setEndPoint={setEndPoint} color='#000' setOpenModal={setOpenModal} type={'text'} setType={setType} label='Image Background' setLabel={setLabel} id={regla.id} setId={setId}/>
                                <Typography dangerouslySetInnerHTML={{__html: regla.contingut}}  variant='body1' paddingLeft='' marginTop='' marginBottom='10px'></Typography>

                            </Box>
                        )
                    })}
                    
				</Loading>
                
            </Box>
            <EditModal 
                open={openModal}
                setOpen={setOpenModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                type={type}
                name={name}
                label={label}
                endPoint={endPoint}
                id={id}
                productName={productName}
            />
		</PageAdmin>
	);
}

export default ReglamentAdmin;
