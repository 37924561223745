import React from "react";
import { Navigate } from "react-router-dom";
import AdminLayout from "./layouts/admin";
import PublicLayout from "./layouts/public";
import AddProducte from "./views/admin/Botiga/AddProducte";
import AddTalla from "./views/admin/Botiga/AddTalla";
import BotigaAdmin from "./views/admin/Botiga/BotigaAdmin";
import HomeEdit from "./views/admin/Casserrenca/HomeEdit";
import AddFotograf from "./views/admin/Casserrenques/AddFotograf";
import AddVideo from "./views/admin/Casserrenques/AddVideo";
import Casserrenques from "./views/admin/Casserrenques/Casserrenques";
import ComandaView from "./views/admin/Comandes/ComandaView";
import Comandes from "./views/admin/Comandes/Comandes";
import CrearCasserrenca from "./views/admin/CrearCasserrenca";
import AddCursa from "./views/admin/Cursa/AddCursa";
import HomeAdmin from "./views/admin/home/HomeAdmin";
import LoginView from "./views/admin/login/LoginView";
import RegisterView from "./views/admin/login/RegisterView";
import AddReglament from "./views/admin/Reglament/AddReglament";
import ReglamentAdmin from "./views/admin/Reglament/ReglamentAdmin";
import Anteriors from "./views/public/Anteriors";
import Botiga from "./views/public/Botiga";
import Checkout from "./views/public/Botiga/Checkout";
import Finalitzar from "./views/public/Botiga/Finalitzar";
import Home from "./views/public/Home";
import Intro from "./views/public/Intro";
import Reglament from "./views/public/Reglament";

const routes = (logged, addItemShopCart, removeItemShopCart, totalPrice, shopCart, isMobile, setShopCart, setTotalPrice) => [
	{
		path: "/admin",
		element: logged ? <AdminLayout /> : <Navigate to="/login" />,
		children: [
			{
				path: "/admin/dash",
				element: <HomeAdmin title="Home" />,
			},
			{ path: "/admin/crear-casserrenca", element: <CrearCasserrenca /> },
			{ path: "/admin/la-casserrenca", element: <HomeEdit /> },
			{ path: "/admin/casserrenques", element: <Casserrenques /> },
			{ path: "/admin/botiga", element: <BotigaAdmin /> },
			{ path: "/admin/botiga/producte/nou", element: <AddProducte /> },
			{ path: "/admin/botiga/producte/:id/talla/nou", element: <AddTalla /> },
			{ path: "/admin/comandes", element: <Comandes /> },
            { path: '/admin/comandes/:id', element: <ComandaView /> },
			{ path: "/admin/reglament", element: <ReglamentAdmin /> },
            { path: '/admin/cursa/:id/nou', element: <AddCursa /> },
			{ path: "/admin/casserrenques/casserrenca/:id/video/nou", element: <AddVideo /> },
			{ path: "/admin/casserrenques/casserrenca/:id/fotograf/nou", element: <AddFotograf /> },
			{ path: "/admin/reglament/casserrenca/:id/nou", element: <AddReglament /> },
		],
	},
	{
		path: "/",
		element: <PublicLayout f_addItemShopCart={addItemShopCart} f_removeItemShopCart={removeItemShopCart} totalPrice={totalPrice} shopCart={shopCart} isMobile={isMobile}/>,
		children: [
			{
				path: "/login",
				element: !logged ? (
					<LoginView />
				) : (
					<Navigate to="/admin/dash" />
				),
			},
			{
				path: "/registre",
				element: !logged ? (
					<RegisterView />
				) : (
					<Navigate to="/admin/dash" />
				),
			},
			{ path: "/", element: <Home isMobile={isMobile}/> },
			{ path: "/botiga", element: <Botiga f_addItemShopCart={addItemShopCart} f_removeItemShopCart={removeItemShopCart} totalPrice={totalPrice} shopCart={shopCart} isMobile={isMobile}/> },
			{ path: "/tramitar", element: <Checkout f_setShopCart={setShopCart} f_setTotalPrice={setTotalPrice}/> },
            { path: '/tramitar/finalitzar', element: <Finalitzar /> },
			{ path: "/anteriors", element: <Anteriors isMobile={isMobile}/> },
			{ path: "/reglament", element: <Reglament isMobile={isMobile} /> },

		],
	},
];

export default routes;
