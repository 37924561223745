import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    TextField,
    Button,
    InputLabel,
    Select,
    Typography,
    MenuItem,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Title from '../../../layouts/admin/Title';
import { create, get } from '../../../API/API';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { FormControl } from '@mui/material';
import Page from '../../../components/layouts/Page';
import Thumb from '../../../components/elements/Thumb'
import PageAdmin from '../../../components/layouts/PageAdmin';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        padding: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
    title: {
        padding: 20
    },
    wrap: {
        padding: 40,
        borderRadius: 5,
        boxShadow:
            '-1px 2px 4px -1px rgb(0 0 0 / 3%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 4%)',
    },
}));

const AddCursa = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
	const [horaSortida, setHoraSortida] = useState("");
	const [horaArribada, setHoraArribada] = useState("");

    const {id} = useParams()

    const generateTimeArray = () => {
		const timesArray = [];
		for (let hour = 0; hour < 24; hour++) {
			for (let minutes = 0; minutes < 60; minutes += 30) {
				let timeString = "";
				if (hour < 10) {
					timeString += `0${hour}:`;
				} else {
					timeString += `${hour}:`;
				}
				if (minutes < 10) {
					timeString += `0${minutes}`;
				} else {
					timeString += `${minutes}`;
				}
				timesArray.push(timeString);
			}
		}
		return timesArray;
	};

    const crearProducte = async (values) => {
        
        const res = {
            nom: values.nom,
            distancia: values.distancia,
            desnivell: values.desnivell,
            foto: values.foto,
            hora_sortida: horaSortida,
            hora_arribada: horaArribada,
            track: values.track,
            text: values.text,
            casserrenca_id: id
        }

        const result = await create('afegirCursa', res);
        enqueueSnackbar(result.message, {
            variant: 'success',
        });
        navigate(-1, { state: values });
    };

    return (
        <PageAdmin title="Add Cursa">
            <Formik
                initialValues={{
                    nom: '',
                    distancia: 0,
                    desnivell: 0,
                    foto: '',
                    hora_sortida: '',
                    hora_arribada: '',
                    track: '',
                    text: ''
                }}
                // validationSchema={Yup.object().shape({
                //     name: Yup.string().required('El nom és obligatòri')
                // })}
                onSubmit={(values) => {
                    crearProducte(values);
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div style={{padding: '0 40px'}}>
                            <Title
                                title={'Afegir Cursa'}
                                subtitle={
                                    'Entra les dades per guardar una nova entrada'
                                }
                            />
                        </div>
                        <Box className={classes.wrap}>
                            <Grid container spacing={3}>
                                <Grid item md={6}>
                                    <TextField
                                        error={Boolean(
                                            touched.nom && errors.nom
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.nom && errors.nom
                                        }
                                        label="Nom"
                                        margin="normal"
                                        name="nom"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.nom}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        error={Boolean(
                                            touched.distancia &&
                                                errors.distancia
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.distancia &&
                                            errors.distancia
                                        }
                                        label={'Distancia'}
                                        margin="normal"
                                        name="distancia"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="double"
                                        value={values.distancia}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        error={Boolean(
                                            touched.desnivell &&
                                                errors.desnivell
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.desnivell &&
                                            errors.desnivell
                                        }
                                        label={'Desnivell'}
                                        margin="normal"
                                        name="desnivell"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="double"
                                        value={values.desnivell}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                
                                <Grid item md={6} xs={12}>
                                    <InputLabel htmlFor="tipus">
                                        Hora sortida
                                    </InputLabel>
                                    <Select
                                        name="hora_sortida"
                                        value={horaSortida}
                                        onChange={(v) =>
                                            setHoraSortida(v.target.value)
                                        }
                                        label="Hora incial"
                                        type="time"
                                        fullWidth
                                    >
                                        {generateTimeArray().map(
                                            (item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <InputLabel htmlFor="tipus">
                                        Hora arribada
                                    </InputLabel>
                                    <Select
                                        name="hora_arribada"
                                        value={horaArribada}
                                        onChange={(v) =>
                                            setHoraArribada(v.target.value)
                                        }
                                        label="Hora arribada"
                                        type="time"
                                        fullWidth
                                    >
                                        {generateTimeArray().map(
                                            (item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.text &&
                                                errors.text
                                        )}
                                        fullWidth
                                        multiline
                                        helperText={
                                            touched.text &&
                                            errors.text
                                        }
                                        label={'Descripció de la cursa'}
                                        margin="normal"
                                        name="text"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        rows={2}
                                        type="text"
                                        value={values.text}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Box mt={2}>
                                        <Typography>Imatge</Typography>
                                        <input
                                            id="foto"
                                            name="foto"
                                            type="file"
                                            inputProps={{ accept: "image/png, image/jpeg"}}
                                            required={false}
                                            onChange={(event) => {
                                                setFieldValue(
                                                    'foto',
                                                    event.currentTarget.files[0]
                                                );
                                            }}
                                        />
                                    </Box>
                                    <Box p={2}>
                                        <Thumb file={values.foto} />
                                    </Box>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.track &&
                                                errors.track
                                        )}
                                        fullWidth
                                        multiline
                                        helperText={
                                            touched.track &&
                                            errors.track
                                        }
                                        label={'Enllaç Track'}
                                        margin="normal"
                                        name="track"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        rows={2}
                                        type="text"
                                        value={values.track}
                                    />
                                </Grid>
                            </Grid>

                            <Box my={2} sx={{maxWidth: '50%', paddingTop: '40px', margin: '0 auto'}}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {'crear'}
                                </Button>
                            </Box>
                        </Box>
                    </form>
                )}
            </Formik>
        </PageAdmin>
    );
};

export default AddCursa;
