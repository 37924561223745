/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    TextField,
    Button,
    InputLabel,
    Select,
    Container,
    Typography,
    Checkbox,
    FormControlLabel,
    Divider,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import Page from '../../../components/layouts/Page';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import Input from '../../../components/elements/Input';
import {
    create,
    sendEmail,
    get,
    updateElement,
    createOrder,
    createOrderProduct,
} from '../../../API/API';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { FormControl } from '@mui/material';
import { ShoppingBasket } from '@mui/icons-material';
import Footer from '../../../layouts/public/Footer';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
    },
    container: {
        marginTop: 47,
        marginBottom: 47,
        padding: 30,
        backgroundColor: theme.palette.background.main,
        boxShadow:
            '-1px 2px 4px -1px rgb(0 0 0 / 3%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 4%)',
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
    wrap: {
        padding: 20,
        backgroundColor: 'white',
        boxShadow:
            '-1px 2px 4px -1px rgb(0 0 0 / 3%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 4%)',
    },
    titleWrap: {
        display: 'flex',
    },
    titleIcon: {
        marginTop: 10,
        marginRight: 30,
        color: '#fff',
    },
    p: {
        marginBottom: '16px !important',
        color: 'white',
    },
    list: {
        backgroundColor: 'white',
    },
    listItem: {
        padding: 10,
    },
}));

const Checkout = (props) => {
    const [productes, setProductes] = useState([]);
    const [enviament, setEnviament] = useState(0);
    const [preu, setPreu] = useState(0);
    const [checked, setChecked] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const shopCart = JSON.parse(localStorage.getItem('shopCart'))
            ? JSON.parse(localStorage.getItem('shopCart'))
            : [];
        setProductes(shopCart);

        const totalPrice = JSON.parse(localStorage.getItem('totalPrice'))
            ? JSON.parse(localStorage.getItem('totalPrice'))
            : [];
        setPreu(totalPrice);

        // const preuEnviament = generarEnviament();
        // setEnviament(preuEnviament);
    }, []);

    function generarEnviament(codi) {
        const shopCart = JSON.parse(localStorage.getItem('shopCart'))
            ? JSON.parse(localStorage.getItem('shopCart'))
            : [];
        const shopCartLen = shopCart?.length;
        let pes = 0;
        for (let i = 0 ; i < shopCartLen ; i++) {
            for (let j = 0 ; j < shopCart[i].quantity ; j++) {
                pes = pes + parseFloat(shopCart[i].product.lot_weight);
            }
        }
        // if (cont > 2) {
        //     return 20;
        // } else if (cont > 1) {
        //     return 15;
        // } else {
        //     return 13;
        // }
        console.log(pes);
        let zipCode = String(codi);
        zipCode = zipCode.slice(0,2)
        console.log(zipCode);
        let enviament = 0;

        switch (zipCode) {
            case '08':
                //ZONA 1: barcelona
                if (pes <= 1) {
                    enviament = 6.10;
                } else if (pes > 1 && pes <= 2) {
                    enviament = 6.10;
                } else if (pes > 2 && pes <= 3) {
                    enviament = 6.10;
                } else if (pes > 3 && pes <= 4) {
                    enviament = 6.80;
                } else if (pes > 4 && pes <= 5) {
                    enviament = 6.95;
                } else if (pes > 5 && pes <= 10) {
                    enviament = 9.10;
                } else if (pes > 10 && pes <= 15) {
                    enviament = 11.70;
                }
                break;
            case '43' || '17' || '25':
                //ZONA 2: lleida, tarragona, girona
                if (pes <= 1) {
                    enviament = 6.60;
                } else if (pes > 1 && pes <= 2) {
                    enviament = 6.60;
                } else if (pes > 2 && pes <= 3) {
                    enviament = 6.95;
                } else if (pes > 3 && pes <= 4) {
                    enviament = 7.25;
                } else if (pes > 4 && pes <= 5) {
                    enviament = 7.80;
                } else if (pes > 5 && pes <= 10) {
                    enviament = 10.00;
                } else if (pes > 10 && pes <= 15) {
                    enviament = 12.90;
                }
                break;
            case '07' || '51' || '52':
                //ZONA 4: balears, ceuta, melilla
                if (pes <= 1) {
                    enviament = 9.60;
                } else if (pes > 1 && pes <= 2) {
                    enviament = 9.60;
                } else if (pes > 2 && pes <= 3) {
                    enviament = 11.80;
                } else if (pes > 3 && pes <= 4) {
                    enviament = 13.90;
                } else if (pes > 4 && pes <= 5) {
                    enviament = 17.40;
                } else if (pes > 5 && pes <= 10) {
                    enviament = 23.70;
                } else if (pes > 10 && pes <= 15) {
                    enviament = 32.90;
                }
                break;
            case '38' || '35' :
                //ZONA 5: las palmas, tenerife
                if (pes <= 1) {
                    enviament = 15.40;
                } else if (pes > 1 && pes <= 2) {
                    enviament = 15.40;
                } else if (pes > 2 && pes <= 3) {
                    enviament = 16.90;
                } else if (pes > 3 && pes <= 4) {
                    enviament = 20.95;
                } else if (pes > 4 && pes <= 5) {
                    enviament = 22.60;
                } else if (pes > 5 && pes <= 10) {
                    enviament = 31.60;
                } else if (pes > 10 && pes <= 15) {
                    enviament = 48.00;
                }
                break;
            default:
                //ZONA 3: andorra, intrapeninsulars
                if (pes <= 1) {
                    enviament = 6.80;
                } else if (pes > 1 && pes <= 2) {
                    enviament = 6.80;
                } else if (pes > 2 && pes <= 3) {
                    enviament = 7.15;
                } else if (pes > 3 && pes <= 4) {
                    enviament = 7.50;
                } else if (pes > 4 && pes <= 5) {
                    enviament = 8.00;
                } else if (pes > 5 && pes <= 10) {
                    enviament = 8.10;
                } else if (pes > 10 && pes <= 15) {
                    enviament = 13.20;
                }
        }
        enviament = (enviament).toFixed(2);
        console.log(enviament);
        return enviament;
    }

    const actualitzarEstoc = async () => {
        let message = 'Missatge';
        productes.map((lot) => {
            //asumim que stock - quantity no pot ser mai menor que zero.
            const stock = lot.product.lot_stock;
            const quantity = lot.quantity;
            const newStock = stock - quantity;
            const values = {
                producte_id: lot.product.producte_id,
                lot_stock: newStock,
            };
            message = updateElement(values, 'update-lot-stock');
            console.log(values, message);
        });
        return message;
    };

    const generarComandesLot = async (id) => {
        if (id) {
            productes?.map((producte) => {
                for (let i = 0; i < producte.quantity; i++) {
                    let values = {
                        comanda_id: id,
                        producte_id: producte.product.id,
                        talla_id: producte.size.id
                    };
                    createOrderProduct('crearComandaProducte', values);
                }
            });
        }
    };

    const enviarEmail = async (
        price,
        id,
        name,
        mail
    ) => {
        
        const values = {
            name: name,
            id: id,
            email: mail,
            price: price
        };
        sendEmail('send-mail', values);
    };

    const crear = async (values) => {
        values.import = (parseFloat(preu) + parseFloat(enviament)).toFixed(2);
        const result = await createOrder('crearComanda', values);
        enqueueSnackbar(result.message, {
            variant: result.message !== 'error' ? 'success' : 'error',
        });
        if (result.message !== 'error') {
            await generarComandesLot(result.data.id);
            // await actualitzarEstoc();
            await enviarEmail(
                result.data.import,
                result.data.id,
                result.data.name,
                result.data.mail
            );
            localStorage.setItem('shopCart', JSON.stringify([]));
            props.f_setShopCart([]);
            localStorage.setItem('totalPrice', JSON.stringify(0));
            props.f_setTotalPrice(0);
            navigate('/tramitar/finalitzar', { state: values });
        } else {
            navigate('/tramitar/finalitzar', { state: 'error' });
        }
    };

    return (
        <Page title="Crear comanda - La Casserrenca" className={classes.root}>
            <Container maxWidth="lg" className={classes.container}>
                <Formik
                    initialValues={{
                        name: '',
                        phone: '',
                        mail: '',
                        zipcode: '',
                        province: '',
                        poblation: '',
                        street: '',
                        number: '',
                        flat: '',
                        import: parseFloat(preu) + parseFloat(enviament),
                        accepta: false,
                        enviament: false
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required(
                            'El nom és obligatòri'
                        ),
                        phone: Yup.string().required(
                            'El telèfon és obligatòri'
                        ),
                        mail: Yup.string()
                            .email()
                            .required("L'E-mail és obligatòri"),
                        accepta: Yup.bool().oneOf(
                            [true],
                            "Has d'acceptar la Política de Privacitat per continuar"
                        ),
                    })}
                    onSubmit={(values) => {
                        crear(values);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box className={classes.titleWrap}>
                                <ShoppingBasket
                                    sx={{ fontSize: 80 }}
                                    className={classes.titleIcon}
                                />
                                <Box>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            lineHeight: 0.9,
                                            color: '#FF0090',
                                        }}
                                    >
                                        <strong>Crea</strong>
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            lineHeight: 0.9,
                                            color: '#fff',
                                        }}
                                    >
                                        la comanda
                                    </Typography>
                                </Box>
                            </Box>
                            <Box mt={5} mb={5}>
                                <Divider light />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item md={4} xs={12}>
                                    <Typography
                                        variant="body1"
                                        className={classes.p}
                                        color='#fff'
                                    >
                                        Introduïu el codi postal del punt
                                        d'entrega per conèixer el cost
                                        d'enviament.
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.p}
                                        color='#fff'
                                    >
                                        Completeu les dades per tal de generar
                                        la comanda. Rebreu un correu electrònic
                                        a l'adreça facilitada, amb els detalls
                                        bancaris i el número de comanda
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.p}
                                        fontFamily='Raleway-bold'
                                        color='#fff'
                                    >
                                        Un cop acabada la fase de prevenda, s’estima que en 15 dies podreu rebre les peces que hagueu demanat. Us ho confirmarem un cop s’hagi activat la fase de personalització de les peces”.
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.p}
                                        color='#fff'
                                    >
                                        En cas de no rebre el correu en els
                                        propers minuts, reviseu la bústia d'spam
                                        del vostre correu o{' '}
                                        <a href="mailto:prova@exemple.com" className='ItemCursa-link'>
                                            contacteu-nos
                                        </a>
                                    </Typography>
                                    <List
                                        aria-label="mailbox folders"
                                        className={classes.list}
                                    >
                                        <Grid
                                            container
                                            alignItems="center"
                                            className={classes.listItem}
                                        >
                                            <Grid item xs>
                                                <Typography
                                                    gutterBottom
                                                    variant="h4"
                                                    component="div"
                                                >
                                                    Total productes
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    gutterBottom
                                                    variant="h4"
                                                    component="div"
                                                >
                                                    {preu.toFixed(2)} €
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <Grid
                                            container
                                            alignItems="center"
                                            className={classes.listItem}
                                        >
                                            <Grid item xs>
                                                <Typography
                                                    gutterBottom
                                                    variant="h4"
                                                    component="div"
                                                >
                                                    Enviament
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    gutterBottom
                                                    variant="h4"
                                                    component="div"
                                                >
                                                    {enviament} €
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <Grid
                                            container
                                            alignItems="center"
                                            className={classes.listItem}
                                        >
                                            <Grid item xs>
                                                <Typography
                                                    gutterBottom
                                                    variant="h4"
                                                    component="div"
                                                >
                                                    Total
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    gutterBottom
                                                    variant="h4"
                                                    component="div"
                                                >
                                                    {(parseFloat(preu) +
                                                        parseFloat(
                                                            enviament
                                                        )).toFixed(2)}{' '}
                                                    €
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </List>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <Box className={classes.wrap} mb={5}>
                                        <Grid container spacing={3}>
                                            <Grid item md={6} xs={12}>
                                                <Input
                                                    error={Boolean(
                                                        touched.name &&
                                                            errors.name
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.name &&
                                                        errors.name
                                                    }
                                                    label="Nom"
                                                    margin="normal"
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.name}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Input
                                                    error={Boolean(
                                                        touched.phone &&
                                                            errors.phone
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.phone &&
                                                        errors.phone
                                                    }
                                                    label="Telèfon"
                                                    margin="normal"
                                                    name="phone"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.phone}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item md={12} xs={12}>
                                                <Input
                                                    error={Boolean(
                                                        touched.mail &&
                                                            errors.mail
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.mail &&
                                                        errors.mail
                                                    }
                                                    label="E-mail"
                                                    margin="normal"
                                                    name="mail"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.mail}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item md={6} xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={values.enviament}
                                                            onChange={handleChange}
                                                            name="enviament"
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="body1">
                                                            Vols que t'ho enviem a casa?
                                                        </Typography>
                                                        
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        {values.enviament ? <Grid container spacing={3}>
                                            <Grid item md={6} xs={12}>
                                                <Input
                                                    error={Boolean(
                                                        touched.zipcode &&
                                                            errors.zipcode
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.zipcode &&
                                                        errors.zipcode
                                                    }
                                                    label="Codi Postal"
                                                    margin="normal"
                                                    name="zipcode"
                                                    onBlur={()=> {
                                                        const preuEnviament = generarEnviament(values.zipcode);
                                                        setEnviament(preuEnviament);
                                                    }}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.zipcode}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Input
                                                    error={Boolean(
                                                        touched.province &&
                                                            errors.province
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.province &&
                                                        errors.province
                                                    }
                                                    label="Província"
                                                    margin="normal"
                                                    name="province"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={
                                                        values.province
                                                    }
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Input
                                                    error={Boolean(
                                                        touched.poblation &&
                                                            errors.poblation
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.poblation &&
                                                        errors.poblation
                                                    }
                                                    label="Població"
                                                    margin="normal"
                                                    name="poblation"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={
                                                        values.poblation
                                                    }
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Input
                                                    error={Boolean(
                                                        touched.street &&
                                                            errors.street
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.street &&
                                                        errors.street
                                                    }
                                                    label="Carrer"
                                                    margin="normal"
                                                    name="street"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.street}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Input
                                                    error={Boolean(
                                                        touched.number &&
                                                            errors.number
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.number &&
                                                        errors.number
                                                    }
                                                    label="Número"
                                                    margin="normal"
                                                    name="number"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.number}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Input
                                                    error={Boolean(
                                                        touched.flat &&
                                                            errors.flat
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.flat &&
                                                        errors.flat
                                                    }
                                                    label="Pis"
                                                    margin="normal"
                                                    name="flat"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.flat}
                                                />
                                            </Grid>
                                        </Grid> : <></>}
                                        {/* <Typography variant="body1" style={{marginTop:"10px", marginBottom:"20px"}}>
                                            Aquests lots seran elaborats per les sòcies de les Dones del Món Rural el cap de setmana del 10 i 11, per tant, fins el dia 3 de Desembre es podrà fer comanda. I partir del dia 13 es farà el repartiment dels lots.
                                        </Typography> */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.accepta}
                                                    onChange={handleChange}
                                                    name="accepta"
                                                />
                                            }
                                            label={
                                                <Typography variant="body1">
                                                    Accepto la política de{' '}
                                                    <a
                                                        href="/politica-privacitat"
                                                        target="_blank"
                                                    >
                                                        privacitat de dades
                                                    </a>
                                                </Typography>
                                                
                                            }
                                        />
                                        <ErrorMessage name="accepta">
                                            {(msg) => (
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        color: 'rgb(211, 47, 47)',
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {msg}
                                                </Typography>
                                            )}
                                        </ErrorMessage>
                                        <Box my={2}>
                                            <Button
                                                color="primary"
                                                fullWidth
                                                size="large"
                                                style={{ color: 'white' }}
                                                type="submit"
                                                variant="contained"
                                            >
                                                {'Generar comanda'}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Container>
        </Page>
    );
};

export default Checkout;
